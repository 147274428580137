import React, { useState } from 'react';

import { ConfigProvider, Divider, Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { teamReducer } from 'reducers/team.reducer';

import { ReactComponent as PlayCircleFilled } from '../../assets/img/icons/faPlayCircleFilled.svg';
import { ReactComponent as PlayCircleFilledDisabled } from '../../assets/img/icons/faPlayCircleFilledDisabled.svg';

const PhasesTooltipAndButton = ({
  match,
  teamPlayerId,
  teamId,
}: {
  match: any;
  teamPlayerId?: string;
  teamId?: string;
}) => {
  const [t] = useTranslation();
  const [openPhasesTooltip, setOpenPhasesTooltip] = useState(false);
  const dispatch = useDispatch();
  const { setStatsCustomGameList } = teamReducer.actions;

  const makeLink = (
    match: any,
    team?: any,
    stat?: any,
    part?: any,
    phase?: any,
  ) => {
    let link = `/player?eventId=${match.id}`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        link = `${link}&teamId=${team?.id}`;
      }
    }
    if (stat) {
      link = `${link}&m=${stat.key}`;
    }
    if (part) {
      link = `${link}&p=${part}`;
    }
    if (phase) {
      link = `${link}&ph=${phase}`;
    }
    link = `${link}&pl=${teamPlayerId}`;

    dispatch(AC_setIntermediateActivitiesAction(match ? [match.id] : []));
    dispatch(setStatsCustomGameList(match ? [match.id] : []));
    return link;
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: 'black',
          paddingXS: 4,
          paddingSM: 8,
        },
      }}
    >
      <Tooltip
        title={
          <ConfigProvider
            theme={{
              token: {
                colorText: 'black',
              },
            }}
          >
            <Flex vertical className="phase-hover" style={{ color: 'black' }}>
              <div className="title-grey-small no-hover">{t('Defense a')}</div>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'defence',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Defense a')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'pos_defense',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Positional defense')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="enabledClickable"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  window.open(
                    makeLink(match, { id: teamId }, null, null, 'to_defense'),
                    '_blank',
                  );
                }}
              >
                <PlayCircleFilled />
                {t('Transition to defense')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'defense_standards',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Defence standards')}
              </Flex>
              <Divider style={{ margin: '0 0 0 0' }} />
              <div className="title-grey-small no-hover">{t('Attack')}</div>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'attack',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Attack')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'pos_attack',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Positional attack')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="enabledClickable"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  window.open(
                    makeLink(match, { id: teamId }, null, null, 'to_attack'),
                    '_blank',
                  );
                }}
              >
                <PlayCircleFilled />
                {t('Transition to attack')}
              </Flex>
              <Flex
                align="center"
                gap={8}
                style={{
                  height: 32,
                }}
                className="disabled"
                onClick={(e) => {
                  e.stopPropagation();
                  // dispatch(
                  //   setExpandedGameClickedId({
                  //     id: match.id,
                  //     expanded: expanded.includes(match.id),
                  //   }),
                  // );
                  // navigate(
                  //   makeLink(
                  //     match,
                  //     { id: openedTeamPlayer.team.id },
                  //     null,
                  //     null,
                  //     'attack_standards',
                  //   ),
                  // );
                }}
              >
                <PlayCircleFilledDisabled />
                {t('Attack standards')}
              </Flex>
            </Flex>
          </ConfigProvider>
        }
        placement="right"
        trigger={['click']}
        color="white"
        arrow={false}
        onOpenChange={() => setOpenPhasesTooltip((prev) => !prev)}
        autoAdjustOverflow={false}
      >
        <Flex
          style={{
            height: 34.6,
            width: '100%',
            justifyContent: 'flex-start',
          }}
          className={`enabledClickable ${openPhasesTooltip ? 'buttons-controller-active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PlayCircleFilled />
          {t('Phases...')}
        </Flex>
      </Tooltip>
    </ConfigProvider>
  );
};
export default PhasesTooltipAndButton;
