import React, { useEffect, useRef, useState } from 'react';

import {
  Tooltip as ATooltip,
  ConfigProvider,
  Divider,
  Empty,
  Flex,
  Table,
} from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  FilterApply,
  GetSingleSeasonSelected_stats,
} from 'actions/persona.actions';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';
import { ActivityType } from 'types/playlist.types';

interface GameDataType {
  key: React.Key;
  gameDetails: ActivityType;
}

const GamesSelector = ({
  playerProfileMode,
}: {
  playerProfileMode: string;
}) => {
  // const teamPlayerStatsState = 'loaded';
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { playerId } = useParams();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTableMatches_stats,
    setSelectedRowKeys_stats,
    setSelectedEventsOrComets,
  } = teamPlayerStatsSectionReducer.actions;
  const { selectedRowKeys_stats, tableMatches_stats } = useSelector(
    (state: AppStateType) => state.teamPlayerStatsSectionReducer,
  );
  const controllerRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gameWidth, setGameWidth] = useState<string | number>('100%');
  const onSelectChange = async (
    newSelectedRowKeys: React.Key[],
    _: any,
    info: any,
    filter = true,
  ) => {
    dispatch(setSelectedEventsOrComets([]));
    dispatch(setSelectedRowKeys_stats(newSelectedRowKeys));
    // dispatch(SetSelectedLastMatch(''));
    const seasonId = await dispatch(GetSingleSeasonSelected_stats());
    if (filter) {
      const controller: AbortController = new AbortController();
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      dispatch(
        FilterApply(
          t,
          seasonId,
          seasonId === 'manual' ? newSelectedRowKeys : [],
          playerId || '',
          controllerRef.current.signal,
          'multitool',
        ),
      );
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys_stats,
    onChange: onSelectChange,
  };
  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    if (wrapperRef.current) {
      setGameWidth(wrapperRef?.current?.getBoundingClientRect().width - 48);
    }
  }, [playerProfileMode]);
  return (
    <Flex style={{ overflow: 'hidden' }} ref={wrapperRef}>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'transparent',
            paddingXS: 12,
          },
          components: {
            Table: {
              headerBg: 'transparent',
              borderColor: 'none',
              cellPaddingBlock: 8,
              cellPaddingInline: 8,
              rowSelectedBg: 'none',
            },
          },
        }}
      >
        <Table<GameDataType>
          rowSelection={rowSelection}
          dataSource={tableMatches_stats}
          pagination={false}
          bordered={false}
          rowHoverable={false}
          className="player-stats-game-table"
          rowClassName={'enabledClickable player-stats-game-row'}
          locale={{
            emptyText: (
              <Empty
                description={t('Please, select a season and a tournament')}
              />
            ),
          }}
          onRow={(record) => ({
            onClick: () => {
              const selectedKey: any = record.key;
              let x: string[];
              if (selectedRowKeys_stats === undefined) {
                onSelectChange([selectedKey], {}, {}, true);
                x = [selectedKey];
              } else if (selectedRowKeys_stats.includes(selectedKey)) {
                // If selected, deselect it
                x = selectedRowKeys_stats.filter(
                  (key: any) => key !== selectedKey,
                );
                onSelectChange(x, {}, {}, true);
              } else {
                // Otherwise, add it to the selection
                x = [...selectedRowKeys_stats, selectedKey];
                onSelectChange(x, {}, {}, true);
              }
              // dispatch(setSelectedRowKeys_stats(x));
            },
          })}
        >
          <Column
            title={
              <div className="popover-matches-column-header">
                {t('All matches 2')}
              </div>
            }
            render={(match: any) => {
              return (
                <div
                  className="oy-h"
                  style={{
                    maxWidth: gameWidth,
                    minWidth: '20px',
                    marginLeft: '-8px',
                  }}
                >
                  <div
                    className={`games_filter_game_block_name ${selectedRowKeys_stats?.includes(match.key) ? 'color-text-heading' : 'color-text-label'}`}
                  >
                    {match.gameDetails.home_team}{' '}
                    {match.gameDetails.score?.score.replace('-', ':')}{' '}
                    {match.gameDetails.away_team}
                  </div>
                  <div
                    className={`games_filter_game_block_info ${selectedRowKeys_stats?.includes(match.key) ? 'color-text-label' : 'color-description'}`}
                  >
                    <div style={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                      {match.gameDetails.date}
                    </div>
                    {match.gameDetails.country && (
                      <>
                        <div>
                          <Divider
                            type="vertical"
                            style={{
                              margin: '0 0 0 0',
                              borderColor:
                                'var(--colorFill, rgba(0, 0, 0, 0.15))',
                            }}
                          />
                        </div>
                        <ATooltip
                          title={
                            match.gameDetails.country.length > 14 &&
                            match.gameDetails.country
                          }
                          arrow={true}
                        >
                          <div
                            style={{ maxWidth: 100 }}
                            className="elipsis-text"
                          >
                            {match.gameDetails.country}
                          </div>
                        </ATooltip>
                        <div>
                          <Divider
                            type="vertical"
                            style={{
                              margin: '0 0 0 0',
                              borderColor:
                                'var(--colorFill, rgba(0, 0, 0, 0.15))',
                            }}
                          />
                        </div>
                        <ATooltip
                          title={
                            match.gameDetails.league.length > 14 &&
                            match.gameDetails.league
                          }
                          arrow={true}
                        >
                          <div
                            style={{ maxWidth: 200 }}
                            className="elipsis-text"
                          >
                            {match.gameDetails.league}
                          </div>
                        </ATooltip>
                      </>
                    )}
                    {match.gameDetails.tournament && (
                      <>
                        <div>
                          <div
                            style={{ maxWidth: 100 }}
                            className="divider"
                          ></div>
                        </div>
                        <ATooltip
                          title={
                            match.gameDetails.tournament.length > 14 &&
                            match.gameDetails.tournament
                          }
                          arrow={true}
                        >
                          <div>{match.gameDetails.tournament}</div>
                        </ATooltip>
                      </>
                    )}
                  </div>
                </div>
              );
            }}
          />
        </Table>
      </ConfigProvider>
    </Flex>
  );
};

export default GamesSelector;
