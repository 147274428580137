/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import MatchSkeleton from 'components/Skeletons/MatchSkeleton';
import { AppStateType } from 'reducers';
import { metricsReducer } from 'reducers/metrics.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';

import MatchFilters from './MatchFilters';
import MatchRow from './MatchRow';

const PlayerMatchesBlock = () => {
  const [t] = useTranslation();
  const {
    setTopPlayerRole,
    setGamesFilterPositions,
    setPositions,
    setPositionsDurations,
    setPositionsFilterDurations,
    setPositionsLoading,
  } = teamPlayerReducer.actions;
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    setpositionsLoading_stats,
    // setGamesFilterPositions_stats,
    setPositions_stats,
    setPositionsDurations_stats,
  } = teamPlayerStatsSectionReducer.actions;
  const [matches, setMatches] = useState<any>([]);
  const dispatch = useDispatch();
  const { playerId } = useParams();

  useEffect(() => {
    if (playerId) {
      dispatch(setPositionsLoading(true));
      dispatch(setpositionsLoading_stats(true));
      PlayersAPI.getPlayerRoles(playerId)
        .then((response) => {
          const allPositions = [
            // {
            //   value: 'ALL',
            //   label: t('All positions'),
            //   clasName: 'select-divider',
            // },
            ...response.data.roles.map((role: string) => {
              return { value: role, label: t(role) };
            }),
          ];
          dispatch(setGamesFilterPositions(allPositions));
          // dispatch(setGamesFilterPositions_stats(allPositions));
          dispatch(setPositions(allPositions));
          dispatch(setPositions_stats(allPositions));
          dispatch(setPositionsDurations(response.data.durations));
          dispatch(setPositionsDurations_stats(response.data.durations));
          dispatch(setPositionsFilterDurations(response.data.durations));
          // dispatch(setPositionsFilterDurations_stats(response.data.durations));
          const allSchemas: any = [
            ...response.data.schemas.map((schema: any) => {
              return {
                value: schema.id,
                label: schema.name,
              };
            }),
          ];
          setPlayedSchemas(allSchemas);
          dispatch(setTopPlayerRole(response.data.top_roles));
        })
        .catch(() => {
          dispatch(setPositionsLoading(false));
          dispatch(setpositionsLoading_stats(false));
        });
      dispatch(setPositionsLoading(false));
      dispatch(setpositionsLoading_stats(false));
    }
  }, [playerId]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPositions, setSelectedPositions] = useState<string | null>(
    null,
  );
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  const [playedSchemas, setPlayedSchemas] = useState([]);
  const [selectedPlayedSchemas, setSelectedPlayedSchemas] = useState<any>(null);
  const { setMetricsRegistry } = metricsReducer.actions;
  useEffect(() => {
    openedTeamPlayer &&
      EventsAPI.getMetricsRegistry(
        'player',
        null,
        openedTeamPlayer.team.sport,
      ).then((response) => {
        dispatch(setMetricsRegistry(response.data));
      });
  }, [openedTeamPlayer]);
  const MATCHES_PER_PAGE = 1000;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!playerId) {
      return;
    }
    setLoading(true);
    EventsAPI.getPlayerGamesStats(
      null,
      1,
      MATCHES_PER_PAGE,
      selectedMinutes || 'ANY',
      selectedPlayedSchemas || 'ALL',
      selectedPositions || 'ALL',
      playerId,
    ).then((matchesStatsResponse) => {
      if (matchesStatsResponse.data) {
        setMatches(matchesStatsResponse.data.results); // TODO add pagination later
        setTotal(matchesStatsResponse.data.total);
      }
      setLoading(false);
    });
  }, [selectedMinutes, selectedPlayedSchemas, selectedPositions, playerId]);

  const matchesRef = useRef<any>(null);
  return (
    <Flex vertical style={{ width: '100%' }} gap={8}>
      <Flex
        className="block-title"
        justify="flex-start"
        style={{ width: '100%' }}
        gap={16}
      >
        <div className="block-title-h" style={{ flex: 0 }}>
          {t('Matches')}
        </div>
        <MatchFilters
          show={openedTeamPlayer && openedTeamPlayer.team.sport !== 2}
          selectedPositions={selectedPositions}
          setSelectedPositions={setSelectedPositions}
          playedSchemas={playedSchemas}
          setPlayedSchemas={setPlayedSchemas}
          selectedPlayedSchemas={selectedPlayedSchemas}
          setSelectedPlayedSchemas={setSelectedPlayedSchemas}
          selectedMinutes={selectedMinutes}
          setSelectedMinutes={setSelectedMinutes}
        />
      </Flex>
      <Flex vertical gap={8} ref={matchesRef}>
        {!loading &&
          matches.map((match: any) => (
            <MatchRow key={match.id} match={match} matches={matches} />
          ))}
        {loading && <MatchSkeleton />}
        <div ref={matchesRef} style={{ alignSelf: 'stretch', height: 1 }}></div>
      </Flex>
    </Flex>
  );
};
export default PlayerMatchesBlock;
