/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  FilterApply,
  GetSingleSeasonSelected_stats,
} from 'actions/persona.actions';
import { GetMultitoolEvents } from 'actions/playerMultitool.actions';
import { makeStatsPlaceholders } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import GamesSelector from './GamesSelector';
import SeasonsSelector from './SeasonsSelector';

import './index.css';

const SeasonsAndGamesSelector = ({
  metricsControllerRef,
  selectedMetricFilters,
  playerProfileMode,
}: {
  metricsControllerRef: any;
  selectedMetricFilters: any;
  playerProfileMode: string;
}) => {
  const {
    teamPlayerStatsMatches_stats,
    statsState_stats,
    selectedRowKeys_stats,
    selectedStat,
    selectedPosition_stats,
    teamPlayerStats_stats,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const { playerId } = useParams();
  const {
    setAppliedSeasons_stats,
    setStatsState_stats,
    setTeamPlayerStats_stats,
    setSelectedFromFieldsCells,
    setSelectedToFieldsCells,
    setDisplayedComets,
    setMatchedPointEvents,
    setFromFieldEventsDetails,
    setToFieldEventsDetails,
    setMultitoolEvents,
    setMultitoolEventsLoading,
    setSelectedStat,
  } = teamPlayerStatsSectionReducer.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const controllerRef = useRef<any>(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const STATS_PLACEHOLDER = makeStatsPlaceholders(t);
  const updateMultitoolEvents = async () => {
    const metricsController: AbortController = new AbortController();
    if (metricsControllerRef.current) {
      metricsControllerRef.current.abort();
    }
    metricsControllerRef.current = metricsController;
    // await dispatch(setSelectedFromFieldsCells([]));
    // await dispatch(setSelectedToFieldsCells([]));
    await dispatch(setDisplayedComets([]));
    console.log(2);
    await dispatch(setMatchedPointEvents([]));
    await dispatch(setFromFieldEventsDetails([]));
    await dispatch(setToFieldEventsDetails([]));
    await dispatch(setMultitoolEvents([]));
    if (selectedStat) {
      await dispatch(
        GetMultitoolEvents(
          selectedStat.key,
          playerId || '',
          selectedRowKeys_stats || [],
          selectedPosition_stats,
          selectedMetricFilters,
          metricsControllerRef.current.signal,
        ),
      );
    }
    return () => {
      metricsControllerRef.current.abort();
    };
  };
  const updateStats = async (seasonId: string) => {
    const controller: AbortController = new AbortController();
    const metricsController: AbortController = new AbortController();
    const singleSeasonId = await dispatch(GetSingleSeasonSelected_stats());
    if (
      singleSeasonId &&
      singleSeasonId !== 'manual' &&
      selectedRowKeys_stats
      // selectedRowKeys.length === 0
    ) {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      if (metricsControllerRef.current) {
        metricsControllerRef.current.abort();
      }
      metricsControllerRef.current = metricsController;
      // console.log('1');

      // await dispatch(setSelectedFromFieldsCells([]));
      // await dispatch(setSelectedToFieldsCells([]));
      await dispatch(setDisplayedComets([]));
      await dispatch(setMatchedPointEvents([]));
      await dispatch(setFromFieldEventsDetails([]));
      await dispatch(setToFieldEventsDetails([]));
      await dispatch(setMultitoolEvents([]));
      await dispatch(setMultitoolEventsLoading(true));
      const filteredStats = await dispatch(
        FilterApply(
          t,
          singleSeasonId,
          [],
          playerId || '',
          controllerRef.current.signal,
          'multitool',
        ),
      );
      if (!selectedStat && filteredStats?.length) {
        const firstNotNull = filteredStats.filter(
          (stat: any) => stat.stat.total,
        )[0];
        if (firstNotNull) {
          await dispatch(
            setSelectedStat({
              key: firstNotNull.key,
              as_point: firstNotNull.as_point,
            }),
          );
        }
      }
      if (selectedStat) {
        await dispatch(
          GetMultitoolEvents(
            selectedStat.key,
            playerId || '',
            selectedRowKeys_stats || [],
            selectedPosition_stats,
            selectedMetricFilters,
            metricsControllerRef.current.signal,
          ),
        );
      }
      return () => {
        controllerRef.current.abort();
        metricsControllerRef.current.abort();
      };
    } else if (
      singleSeasonId &&
      selectedRowKeys_stats &&
      selectedRowKeys_stats.length > 0
    ) {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      // console.log('2');
      if (metricsControllerRef.current) {
        metricsControllerRef.current.abort();
      }
      metricsControllerRef.current = metricsController;
      // await dispatch(setSelectedFromFieldsCells([]));
      // await dispatch(setSelectedToFieldsCells([]));
      await dispatch(setDisplayedComets([]));
      console.log(5);
      await dispatch(setMatchedPointEvents([]));
      await dispatch(setFromFieldEventsDetails([]));
      await dispatch(setToFieldEventsDetails([]));
      await dispatch(setMultitoolEvents([]));
      await dispatch(setMultitoolEventsLoading(true));
      const filteredStats = await dispatch(
        FilterApply(
          t,
          singleSeasonId,
          selectedRowKeys_stats,
          playerId || '',
          controllerRef.current.signal,
          'multitool',
        ),
      );
      if (!selectedStat && filteredStats?.length) {
        const firstNotNull = filteredStats.filter(
          (stat: any) => stat.stat.total,
        )[0];
        await dispatch(
          setSelectedStat({
            key: firstNotNull.key,
            as_point: firstNotNull.as_point,
          }),
        );
      }
      if (selectedStat) {
        await dispatch(
          GetMultitoolEvents(
            selectedStat.key,
            playerId || '',
            selectedRowKeys_stats || [],
            selectedPosition_stats,
            selectedMetricFilters,
            metricsControllerRef.current.signal,
          ),
        );
      }
      return () => {
        controllerRef.current.abort();
        metricsControllerRef.current.abort();
      };
    } else if (statsState_stats === 'loading') {
      const finalStats: any = [];
      STATS_PLACEHOLDER.forEach((stat: any) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          stat: null,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
          disabled: stat.disabled,
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              disabled: childStat.disabled,
              stat: null,
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      dispatch(setTeamPlayerStats_stats(finalStats));
      dispatch(setStatsState_stats('loaded'));
    }
  };
  // useEffect(() => {
  // updateMultitoolEvents();
  // }, [selectedMetricFilters]);
  useEffect(() => {
    if (teamPlayerStatsMatches_stats.length) {
      setSelectedSeason(teamPlayerStatsMatches_stats[0].leagues[0]?.season_id);
      updateStats(teamPlayerStatsMatches_stats[0].leagues[0]?.season_id);
      dispatch(
        setAppliedSeasons_stats([teamPlayerStatsMatches_stats[0]?.name]),
      );
      setSelectedSeasonLabel(teamPlayerStatsMatches_stats[0].name);
    }
  }, [teamPlayerStatsMatches_stats, selectedRowKeys_stats]);
  return (
    <Flex vertical gap={8} className="player-multitool-seasons-wrapper">
      <SeasonsSelector
        setSelectedSeason={setSelectedSeason}
        playerProfileMode={playerProfileMode}
      />
      <GamesSelector playerProfileMode={playerProfileMode} />
    </Flex>
  );
};
export default SeasonsAndGamesSelector;
