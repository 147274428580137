import React, { FC, useRef } from 'react';

import { Checkbox, ConfigProvider } from 'antd';
import { Tooltip as ATooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
  AC_SelectTimingIntervalFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetSelectedAttributes,
  GetEpisodesAttributesRegister,
} from 'actions/filter.actions';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import dayjs from 'utils/dayjsLocal';

import Loader from '../../../Loader';

interface GamesFilterInterface {
  filterType: string;
  selectedGamesController: any;
}
const GamesFilter: FC<GamesFilterInterface> = ({
  filterType,
  selectedGamesController,
}) => {
  const dispatch = useAppDispatch();
  const {
    selectedGames,
    activeTeam,
    prefilteredGamesLoading,
    prefilteredGames,
  } = useSelector((state: AppStateType) => state.filtersReducer);
  const toggleGame = (gameId: string) => {
    if (activeTeam) {
      // TODO доделать с учетом команды
      let newVal = [...selectedGames];
      if (selectedGames.includes(gameId)) {
        newVal = [...selectedGames].filter((id: string) => id !== gameId);
      } else {
        if (newVal.length > 0) {
          newVal = [...newVal, gameId];
        } else {
          newVal = [gameId];
        }
      }
      dispatch(AC_SelectedMatchesFilterAction(newVal));
      dispatch(GetEpisodesAttributesRegister());
      dispatch(AC_SelectTimingIntervalFilterAction([]));
      dispatch(AC_SetSelectedAttributes([]));
      // selectedGamesController.setSelectedGames(newVal);
    }
  };
  const [t] = useTranslation();
  const TITLE: any = {
    game: 'Matches',
    training: 'Trainings',
  };
  const isGameSelected = (game: any) => {
    return activeTeam !== null && selectedGames.includes(game.id);
  };
  const getGameName = (game: any) => {
    return game.type === 'game'
      ? `${game.home_team} ${game.result} ${game.away_team}`
      : '';
  };
  const labelRefs = useRef<Array<any>>([]);

  return (
    <>
      <div className="gamesFilterContainer_title">
        {t(TITLE[filterType])}{' '}
        {activeTeam &&
          selectedGamesController.selectedGames[activeTeam.toString()]?.length >
            0 &&
          `(${selectedGamesController.selectedGames[activeTeam.toString()]?.length})`}
      </div>
      {prefilteredGamesLoading && <Loader />}
      {!prefilteredGamesLoading &&
        prefilteredGames.map((game: any, index: number) => {
          return (
            <div
              className="games_filter_game_block enabledClickable"
              onClick={() => toggleGame(game.id)}
              key={index}
              //@ts-ignore
              ref={(el) => labelRefs.current.push(el)}
            >
              <div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgContainer: '#141414',
                      colorBorder: '#424242',
                      lineWidth: 1,
                    },
                  }}
                >
                  <Checkbox
                    id={game.id}
                    checked={isGameSelected(game)}
                  ></Checkbox>
                </ConfigProvider>
              </div>
              <div className="oy-h">
                <div className="games_filter_game_block_name">
                  {getGameName(game)}
                </div>
                <div className="games_filter_game_block_info">
                  <div>{dayjs(game.started_at).format('DD.MM.YYYY')}</div>
                  {game.country && (
                    <>
                      <div>
                        <div className="divider"></div>
                      </div>
                      <ATooltip title={game.country} arrow={true}>
                        <div className="elipsis-text">{game.country}</div>
                      </ATooltip>
                    </>
                  )}
                  {game.tournament && (
                    <>
                      <div>
                        <div className="divider"></div>
                      </div>
                      <ATooltip title={game.tournament} arrow={true}>
                        <div>{game.tournament}</div>
                      </ATooltip>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      <Tooltip
        variant={'dark'}
        id={'gameNameTooltip'}
        noArrow={false}
        className={'videoAttributesTooltip'}
        place={'bottom-end'}
        globalCloseEvents={{
          escape: true,
          resize: true,
          clickOutsideAnchor: true,
          scroll: true,
        }}
      />
    </>
  );
};
export default GamesFilter;
