/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import MetricPlayerButtonWithPercent from 'components/TeamPageController/MetricPlayerButtonWIthPercent';
import { AppStateType } from 'reducers';
import { STATS_PLACEHOLDER } from 'types/constants';

import AverageStatTeamValueTag from '../AverageStatTeamValueTag';
import ConsolidatedStatValueTag from '../ConsolidatedStatValueTag';

import './index.css';

const PerPlayerStatistics = ({
  teamValuesStatsMode,
}: {
  teamValuesStatsMode: any;
}) => {
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playersStats, setPlayersStats] = useState<any[]>(STATS_PLACEHOLDER);
  const [playersStatsData, setPlayersStatsData] =
    useState<any[]>(STATS_PLACEHOLDER);
  const { teamData } = useSelector((state: AppStateType) => state.teamReducer);
  const [selectedStat, setSelectedStat] = useState<string | null>(null);
  const toggleSelectedStat = (stat: any) => {
    if (selectedStat !== stat.key) {
      setSelectedStat(stat.key);
      setSelectedMetricFilters([]);
      setHoveredMetricFilters((prev) => [stat.key]);
    }
  };
  const onMousEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
    metricKey: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          // ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
    if (!selectedStat?.includes(metricKey)) {
      setSelectedStat(metricKey);
      setHoveredMetricFilters([metricKey]);
    }
  };
  return (
    <Flex
      style={{
        margin: '0px 0px 0px 0px',
        padding: '8px 0px 8px 8px',
        width: '100%',
      }}
      justify="space-between"
      gap={8}
    >
      <Flex style={{ flex: 1 }} vertical gap={8}>
        <div style={{ flex: 1, marginLeft: 12 }} className="stats-header-v2">
          {t('Metric')}
        </div>
        <Flex vertical gap={8}>
          {playersStats.map((stat: any) => (
            <Flex gap={8} vertical>
              <Flex
                align="space-between"
                className={`w100 team-profile-metric-row ${selectedStat === stat.key && 'selected'}`}
                onClick={() => toggleSelectedStat(stat)}
                onMouseEnter={(e: any) => {
                  onMousEnter(e, stat);
                }}
                onMouseLeave={() => {
                  if (selectedStat !== stat.key) {
                    setHoveredMetricFilters((prev) =>
                      prev.filter((el: string) => el !== stat.key),
                    );
                  }
                }}
              >
                <div>{stat.label}</div>
                <MetricFilter
                  asPoint={false}
                  metric={stat.key}
                  selectedMetricFilters={selectedMetricFilters}
                  hoveredMetricFilter={hoveredMetricFilter}
                  toggleSelectedMetric={toggleSelectedMetric}
                />
              </Flex>
              {stat.children?.map((detailStat: any) => (
                <Flex
                  align="space-between"
                  className={`w100 team-profile-metric-row ${selectedStat === detailStat.key && 'selected'}`}
                  style={{ paddingLeft: 36 }}
                  onClick={() => toggleSelectedStat(detailStat)}
                  gap={8}
                >
                  <div>{detailStat.label}</div>
                  <MetricFilter
                    asPoint={false}
                    metric={detailStat.key}
                    selectedMetricFilters={selectedMetricFilters}
                    hoveredMetricFilter={hoveredMetricFilter}
                    toggleSelectedMetric={toggleSelectedMetric}
                  />
                </Flex>
              ))}
              {stat.children?.length && (
                <Divider style={{ width: '100%', height: 1, margin: 0 }} />
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Divider
        type="vertical"
        style={{
          margin: '0',
          minHeight: '98%',
          height: 'unset',
          width: 1,
        }}
      />
      <Flex
        vertical
        style={{
          margin: '0 16px 8px 8px',
          alignSelf: 'stretch',
          minWidth: 274,
          flex: 1,
        }}
        gap={8}
      >
        <Flex>
          <div style={{ flex: 1 }} className="stats-header-v2">
            {t('Opponents')}
          </div>
          <div style={{ minWidth: 130, minHeight: 32 }}>
            {teamValuesStatsMode === 'sum' ? (
              <MetricPlayerButtonWithPercent
                statKey={''}
                stat={{ value: 10, average: 100 }}
                indexStats={0}
                teamId={teamData.id}
                selectedMetricFilters={selectedMetricFilters}
                teamType={''}
              />
            ) : (
              <AverageStatTeamValueTag
                teamId={teamData.id}
                stat={{ value: 20, percent: 10, average: 25 }}
              />
            )}
          </div>
        </Flex>
        <Flex>
          <div style={{ flex: 1 }} className="stats-header-v2">
            {teamData.name}
          </div>
          <div style={{ minWidth: 130, minHeight: 32 }}>
            {teamValuesStatsMode === 'sum' ? (
              <MetricPlayerButtonWithPercent
                statKey={''}
                stat={{ value: 20 }}
                indexStats={1}
                teamId={teamData.id}
                selectedMetricFilters={selectedMetricFilters}
                teamType={''}
              />
            ) : (
              <AverageStatTeamValueTag
                teamId={teamData.id}
                stat={{ value: 20, percent: 10, average: 25 }}
              />
            )}
          </div>
        </Flex>
        {playersStatsData.map((statData: any, index: number) => (
          <Flex justify="space-between">
            <div className="team-profile-player-row">{statData.label}</div>
            <div style={{ minWidth: 130, minHeight: 32 }}>
              {teamValuesStatsMode === 'sum' ? (
                <MetricPlayerButtonWithPercent
                  statKey={''}
                  stat={statData}
                  indexStats={index + 2}
                  teamId={teamData.id}
                  lightVersion={true}
                  selectedMetricFilters={selectedMetricFilters}
                  teamType={''}
                />
              ) : (
                <AverageStatTeamValueTag teamId={teamData.id} stat={statData} />
              )}
            </div>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
export default PerPlayerStatistics;
