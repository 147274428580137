import React, { useState } from 'react';

import { Flex } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import './index.css';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import HorizontalMapWithBlocks from './HorizontalMapWithBlocks';
import MapWithComets from './MapWithComets';

const MapsAndStats = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metricsControllerRef,
  playerProfileMode,
}: {
  metricsControllerRef: any;
  playerProfileMode: string;
}) => {
  const {
    fromFieldEventsDetails,
    toFieldEventsDetails,
    selectedFromFieldCells,
    selectedToFieldCells,
    selectedStat,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const { setSelectedFromFieldsCells, setSelectedToFieldsCells } =
    teamPlayerStatsSectionReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapsState, setMapsState] = useState('comets');
  const dispatch = useAppDispatch();
  const toggleMultipleFromFieldCells = (cellIds: number[]) => {
    const final: number[] = [];
    const forRemoval: number[] = [];
    cellIds.forEach((cellId) => {
      if (!selectedFromFieldCells.includes(cellId)) {
        final.push(cellId);
      } else {
        forRemoval.push(cellId);
      }
    });
    dispatch(
      setSelectedFromFieldsCells([
        ...final,
        ...selectedFromFieldCells.filter((el) => forRemoval.includes(el)),
      ]),
    );
  };
  const toggleMultipleToFieldCells = (cellIds: number[]) => {
    const final: number[] = [];
    const forRemoval: number[] = [];
    cellIds.forEach((cellId) => {
      if (!selectedToFieldCells.includes(cellId)) {
        final.push(cellId);
      } else {
        forRemoval.push(cellId);
      }
    });
    dispatch(
      setSelectedToFieldsCells([
        ...final,
        ...selectedToFieldCells.filter((el) => forRemoval.includes(el)),
      ]),
    );
  };
  const toggleFromFieldCell = async (cellId: number) => {
    if (selectedFromFieldCells.includes(cellId)) {
      // alert('reset 1');
      await dispatch(
        setSelectedFromFieldsCells(
          selectedFromFieldCells.filter((el) => el !== cellId),
        ),
      );
    } else {
      // alert(selectedFromFieldCells);
      // alert(cellId);
      await dispatch(
        setSelectedFromFieldsCells([...selectedFromFieldCells, cellId]),
      );
    }
  };
  const toggleToFieldCell = (cellId: number) => {
    if (selectedToFieldCells.includes(cellId)) {
      dispatch(
        setSelectedToFieldsCells(
          selectedToFieldCells.filter((el: any) => el !== cellId),
        ),
      );
    } else {
      dispatch(setSelectedToFieldsCells([...selectedToFieldCells, cellId]));
    }
  };
  const resetSelectedFromCells = () => {
    dispatch(setSelectedFromFieldsCells([]));
  };
  const resetSelectedToCells = () => {
    dispatch(setSelectedToFieldsCells([]));
  };
  return (
    <Flex flex={1} gap={16}>
      {mapsState === 'comets' ? (
        <Flex vertical flex={1} gap={16} justify="flex-start">
          {!selectedStat?.as_point && (
            <Flex gap={16} justify="space-between" style={{ paddingBottom: 8 }}>
              <HorizontalMapWithBlocks
                resetCallback={resetSelectedFromCells}
                type={'from'}
                fieldEventsDetails={fromFieldEventsDetails}
                toggleFieldCell={toggleFromFieldCell}
                selectedFieldCells={selectedFromFieldCells}
                selectedFromFieldCells={selectedFromFieldCells}
                selectedToFieldCells={selectedToFieldCells}
                toggleMultipleFieldCells={toggleMultipleFromFieldCells}
              />
              <HorizontalMapWithBlocks
                resetCallback={resetSelectedToCells}
                type={'to'}
                fieldEventsDetails={toFieldEventsDetails}
                toggleFieldCell={toggleToFieldCell}
                selectedFieldCells={selectedToFieldCells}
                selectedFromFieldCells={selectedFromFieldCells}
                selectedToFieldCells={selectedToFieldCells}
                toggleMultipleFieldCells={toggleMultipleToFieldCells}
              />
            </Flex>
          )}
          <MapWithComets
            fromFieldEventsDetails={fromFieldEventsDetails}
            toFieldEventsDetails={toFieldEventsDetails}
            as_point={selectedStat?.as_point}
            playerProfileMode={playerProfileMode}
          />
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};
export default MapsAndStats;
