import React from 'react';

import { ConfigProvider, Drawer, Flex } from 'antd';

import TournamentTableBlock from '../TournamentTableBlock';
import './index.css';

const TeamTournamentTableController = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          paddingLG: 0,
        },
      }}
    >
      <Drawer
        width={670}
        open={isOpen}
        onClose={onClose}
        closable={false}
        style={{
          background: 'var(--colorBgLayout, #F5F5F5)',
          borderRadius: '10px 0px 0px 0px',
          padding: '16px 24px',
        }}
        title={null}
        className="tournament-table-content"
      >
        <Flex
          // justify="center"
          style={{
            width: '100%',
          }}
        >
          <TournamentTableBlock onClose={onClose} />
        </Flex>
      </Drawer>
    </ConfigProvider>
  );
};
export default TeamTournamentTableController;
