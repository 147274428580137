/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';

import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
} from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import GamesSelectionModal from 'components/modals/GamesSelectionModal';
import TournamentTableBlock from 'components/TeamPageController/TournamentTableBlock';
import { TournamentBestPlayersBlock } from 'components/TournamentController/TournamentBestPlayersBlock/TournamentBestPlayersBlock';
import { TournamentMatchesBlock } from 'components/TournamentController/TournamentMatchesBlock/TournamentMatchesBlock';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

export const TournamentPageController: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { setStatsCustomGameList } = teamReducer.actions;
  const { resetReelsState } = reelsReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;
  useEffect(() => {
    dispatch(setStyleModeAction('light'));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showGamesSelectionModalStats, setShowGamesSelectionModalStats] =
    useState('');
  const [selectedMatchesBestPlayers, setSelectedMatchesBestPlayers] =
    useState<any>([]);
  return (
    <>
      <div className="team-page-controller">
        <div className="flex-column f-ga-32" style={{ flex: 1 }}>
          <TournamentMatchesBlock />
        </div>
        <div className="flex-column f-ga-24">
          <TournamentBestPlayersBlock
            selectedMatches={selectedMatchesBestPlayers}
            setSelectedMatches={setSelectedMatchesBestPlayers}
          />
          <TournamentTableBlock onClose={() => {}} />
        </div>
        <GamesSelectionModal />
        <GamesSelectionModal />
      </div>
    </>
  );
};
