import React, { FC, useState, useEffect } from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReelsLengthWarning from 'components/ReelsLengthWarning';
import TopBarDetailsSkeleton from 'components/Skeletons/TopBarDetailsSkeleton';
import { AppStateType } from 'reducers';
import { WINDOW_MODE_WIDTH } from 'types/constants';
import { formatMillisecondsToTimeWithHours } from 'types/functions';
import { getMatchWord } from 'utils/functions';

import { MODES } from '..';

const TopBarDetails: FC<any> = ({
  getTotalEpisodesCount,
}: {
  getTotalEpisodesCount: any;
}) => {
  const [t] = useTranslation();
  const { filteredVideos, playerMode } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { videosListIsLoading } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { activeFilter } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const { playlistIsLoading } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  const { sharedPlaylist, openedPlaylist } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );

  const getEpisodeWord = (num: string) => {
    const lastChar = num.slice(-1);
    if (lastChar === '1') {
      if (num.endsWith('11')) {
        return 'episodes-other';
      } else {
        return 'episodes-1';
      }
    } else if (lastChar === '2' || lastChar === '3' || lastChar === '4') {
      if (num.endsWith('12') || num.endsWith('13') || num.endsWith('14')) {
        return 'episodes-other';
      } else {
        return 'episodes-2';
      }
    }
    return 'episodes-other';
  };
  const { selectedReelsDurationWarning } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const [matchWord, setMatchWord] = useState(getMatchWord('0'));
  const [episodesWord, setEpisodesWord] = useState(getEpisodeWord('0'));
  const getTotalDuration = () => {
    let total = 0;
    if (playerMode === 'episodes') {
      for (const video of filteredVideos) {
        // @ts-ignore
        if (video.filtered_duration) {
          // @ts-ignore
          total += video.filtered_duration / 1000;
        }
      }
    } else if (playerMode === 'playlist_view' && !sharedPlaylist) {
      openedPlaylist?.playlist_events.map((video: any) => {
        video.episodes.map((episode: any) => {
          if (episode.user_timer) {
            total +=
              episode.user_timer.finished_at / 1000 -
              episode.user_timer.started_at / 1000;
          } else {
            total +=
              episode.file_timer.finished_at / 1000 -
              episode.file_timer.started_at / 1000 +
              WINDOW_MODE_WIDTH * 2;
          }
        });
        return null;
      });
    } else if (playerMode === 'playlist_view' && sharedPlaylist) {
      for (const video of sharedPlaylist.playlist_events) {
        for (const episode of video.episodes) {
          if (episode.user_timer) {
            total +=
              episode.user_timer.finished_at / 1000 -
              episode.user_timer.started_at / 1000;
          } else {
            total +=
              episode.file_timer.finished_at / 1000 -
              episode.file_timer.started_at / 1000 +
              WINDOW_MODE_WIDTH * 2;
          }
        }
      }
    }
    return formatMillisecondsToTimeWithHours(total * 1000);
  };
  useEffect(() => {
    setEpisodesWord(getEpisodeWord(getTotalEpisodesCount().toString()));
    setMatchWord(() => {
      if (playerMode === MODES.episodes) {
        return getMatchWord(filteredVideos.length.toString());
      } else if (
        playerMode === MODES.playlist_view &&
        openedPlaylist?.playlist_events?.length !== undefined
      ) {
        return getMatchWord(
          openedPlaylist?.playlist_events?.length?.toString(),
        );
      }
      return getMatchWord('0');
    });
  }, [filteredVideos.length, openedPlaylist]);

  return (
    <Flex
      style={{
        marginLeft: playerMode === MODES.episodes || sharedPlaylist ? 0 : 30,
        width: '100%',
      }}
      justify="flex-start"
      gap={6}
      align="center"
    >
      {playerMode === 'episodes' &&
        (activeFilter || filteredVideos.length > 0) &&
        !playlistIsLoading && (
          <>
            <div>
              {filteredVideos.length} {t(matchWord)}
            </div>
            <div className="filtersPanelTopBar_content_matchLine_divider" />
            <div>
              {getTotalEpisodesCount()} {t(episodesWord)}
            </div>
            <div className="filtersPanelTopBar_content_matchLine_divider" />
            <div>{getTotalDuration()}'</div>
          </>
        )}
      {playerMode === 'episodes' && videosListIsLoading && (
        <TopBarDetailsSkeleton />
      )}
      {playerMode === 'playlist_view' && !openedPlaylist && !sharedPlaylist && (
        <TopBarDetailsSkeleton />
      )}
      {playerMode === 'playlist_view' && openedPlaylist && (
        <>
          <div>
            {openedPlaylist.playlist_events.length} {t(matchWord)}
          </div>
          <div className="filtersPanelTopBar_content_matchLine_divider"></div>
          <div>
            {getTotalEpisodesCount()} {t(episodesWord)}
          </div>
          <div className="filtersPanelTopBar_content_matchLine_divider"></div>
          <div>{getTotalDuration()}'</div>
        </>
      )}
      {playerMode === 'playlist_view' && sharedPlaylist && (
        <>
          <div>
            {sharedPlaylist.playlist_events.length} {t(matchWord)}
          </div>
          <div className="filtersPanelTopBar_content_matchLine_divider"></div>
          <div>
            {getTotalEpisodesCount()} {t(episodesWord)}
          </div>
          <div className="filtersPanelTopBar_content_matchLine_divider"></div>
          <div>{getTotalDuration()}'</div>
        </>
      )}
      {selectedReelsDurationWarning && <ReelsLengthWarning />}
    </Flex>
  );
};
export default TopBarDetails;
