import React, { useEffect, useState } from 'react';

import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ReactComponent as PlayCircleFilled } from 'assets/img/icons/faPlayCircleFilledWhite.svg';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';
import { getEpisodeWord } from 'types/functions';

const ViewEpisodesButton = () => {
  const {
    selectedFromFieldCells,
    selectedToFieldCells,
    fromFieldEventsDetails,
    toFieldEventsDetails,
    matchedPointEvents,
    displayedComets,
    multitoolEvents,
    selectedStat,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedEventsOrComets,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const { playerId } = useParams();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { setSelectedFromFieldsCells, setSelectedEventsOrComets } =
    teamPlayerStatsSectionReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [episodesQty, setEpisodesQty] = useState(0);

  const openFilteredEpisodes = () => {
    const link = makeLink(
      [{ id: null }],
      null,
      [],
      undefined,
      'manual',
      playerId,
      null,
    );
    let eventIds: string[] = [];
    if (
      selectedStat?.as_point &&
      selectedFromFieldCells.length === 0 &&
      selectedToFieldCells.length === 0 &&
      selectedEventsOrComets.length === 0
    ) {
      eventIds = [...multitoolEvents.map((evt) => evt.id)];
    } else if (
      selectedFromFieldCells.length > 0 &&
      selectedToFieldCells.length > 0
    ) {
      eventIds = multitoolEvents
        .filter(
          (evt: any) =>
            selectedFromFieldCells
              .map((cellId: number) => fromFieldEventsDetails[cellId])
              .filter(
                (cell: any) =>
                  cell && cell.events && cell.events.includes(evt.id),
              ).length,
        )
        .filter(
          (evt: any) =>
            selectedToFieldCells
              .map((cellId: number) => toFieldEventsDetails[cellId])
              .filter(
                (cell: any) =>
                  cell && cell.events && cell.events.includes(evt.id),
              ).length,
        )
        .map((el: any) => el.id);
    } else if (selectedFromFieldCells.length || selectedToFieldCells.length) {
      if (selectedFromFieldCells) {
        eventIds = multitoolEvents
          .filter(
            (evt: any) =>
              selectedFromFieldCells
                .map((cellId: number) => fromFieldEventsDetails[cellId])
                .filter(
                  (cell: any) =>
                    cell && cell.events && cell.events.includes(evt.id),
                ).length,
          )
          .map((el: any) => el.id);
      }
      if (selectedToFieldCells) {
        eventIds = [
          ...eventIds,
          ...multitoolEvents
            .filter(
              (evt: any) =>
                selectedToFieldCells
                  .map((cellId: number) => toFieldEventsDetails[cellId])
                  .filter(
                    (cell: any) =>
                      cell && cell.events && cell.events.includes(evt.id),
                  ).length,
            )
            .filter(
              (el) =>
                eventIds.filter((elInner) => elInner === el.id).length === 0,
            )
            .map((el: any) => el.id),
        ];
      }
    } else {
      eventIds = [...multitoolEvents.map((evt) => evt.id)];
    }
    if (selectedEventsOrComets.length) {
      eventIds = eventIds.filter((evtId) =>
        selectedEventsOrComets.includes(evtId),
      );
    }
    localStorage.setItem(`player_events_${link}`, JSON.stringify(eventIds));
    window.open(link, '_blank');
  };
  const getButtonText = () => {
    let text = 'Watch';
    if (selectedStat?.as_point) {
      let qty = multitoolEvents.length;
      if (selectedFromFieldCells.length > 0) {
        qty = matchedPointEvents.length;
      }
      if (selectedEventsOrComets.length) {
        qty = selectedEventsOrComets.length;
      }
      // setEpisodesQty(qty);
      text = t('Watch') + ' ' + qty + ' ' + t(getEpisodeWord(qty.toString()));
    } else {
      let qty = displayedComets.length;
      if (selectedEventsOrComets.length) {
        qty = selectedEventsOrComets.length;
      }
      // setEpisodesQty(qty);
      text = t('Watch') + ' ' + qty + ' ' + t(getEpisodeWord(qty.toString()));
    }
    return text;
  };
  useEffect(() => {
    if (selectedStat?.as_point) {
      let qty = multitoolEvents.length;
      if (selectedFromFieldCells.length > 0) {
        qty = matchedPointEvents.length;
      }
      if (selectedEventsOrComets.length) {
        qty = selectedEventsOrComets.length;
      }
      setEpisodesQty(qty);
    } else {
      let qty = displayedComets.length;
      if (selectedEventsOrComets.length) {
        qty = selectedEventsOrComets.length;
      }
      setEpisodesQty(qty);
    }
  }, [
    selectedStat,
    multitoolEvents,
    matchedPointEvents,
    displayedComets,
    selectedEventsOrComets,
    selectedFromFieldCells,
  ]);
  const resetSelectedFromCells = () => {
    dispatch(setSelectedFromFieldsCells([]));
    dispatch(setSelectedEventsOrComets([]));
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(
      (selectedStat?.as_point && selectedFromFieldCells.length > 0) ||
        selectedEventsOrComets.length > 0,
    );
  }, [selectedStat, selectedFromFieldCells, selectedEventsOrComets]);
  return (
    <Flex gap={22} align="center">
      {show && (
        <div
          style={{
            color: 'var(--colorPrimaryBase, #1677FF)',
          }}
          className="enabledClickable"
          onClick={resetSelectedFromCells}
        >
          {t('Reset')}
        </div>
      )}
      <Button
        type="primary"
        disabled={episodesQty === 0}
        //   (!selectedStat?.as_point && displayedComets.length === 0) ||
        //   (selectedStat?.as_point &&
        //     multitoolEvents.length &&
        //     selectedEventsOrComets.length === 0 &&
        //     matchedPointEvents.length === 0 &&
        //     selectedFromFieldCells.length === 0) ||
        //   (selectedStat?.as_point &&
        //     multitoolEvents.length &&
        //     selectedEventsOrComets.length === 0 &&
        //     matchedPointEvents.length !== 0)
        // }
        onClick={openFilteredEpisodes}
        icon={<PlayCircleFilled />}
      >
        {getButtonText()}
      </Button>
    </Flex>
  );
};
export default ViewEpisodesButton;
