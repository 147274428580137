import React, { FC, useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import ReelsScreenRecorderV3 from '../ReelsScreenRecorderV3';

import './index.css';

const PlayerWrapper: FC<any> = (params) => {
  const { editedEpisodeRange, isPlaying } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { wideScreenMode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  useEffect(() => {
    params.monitorPlayback();
  }, [
    params.playerState?.playedSeconds,
    params.playedEpisode,
    editedEpisodeRange,
  ]);

  const clickCountRef = useRef<number>(0);
  const timeoutRef = useRef<any>(null);
  const handleClick = () => {
    if (reelsModeOn) {
      return;
    }
    clickCountRef.current += 1;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (clickCountRef.current === 1) {
        params.onClick();
      } else if (clickCountRef.current === 2) {
        params.toggleFullScreen((prev: boolean) => !prev);
      }
      clickCountRef.current = 0;
    }, 220);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeoutRef]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [className, setClassName] = useState('');
  useEffect(() => {
    if (wideScreenMode) {
      setClassName('videoPlayer videoPlayer-h100');
    } else {
      setClassName('videoPlayer videoPlayer-auto-height');
    }
  }, [wideScreenMode]);
  return (
    <div
      style={{
        position: 'relative',
        height: wideScreenMode ? '100%' : '',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactPlayer
        muted={params.isMuted}
        config={{
          file: {
            // hlsVersion: "1.4.10",
            // forceHLS: true,
            hlsOptions: {
              forceHLS: false,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              xhrSetup: (xhr: any, url: string) => {
                // if (needsAuth(url)) {
                // xhr.withCredentials = true;
                // xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
                // xhr.setRequestHeader('Referrer-Policy', `no-referrer`);
                // }
              },
            },
            attributes: {
              // preload: 'metadata',
              // preload: 'metadata',
              type: 'video/mp4',
            },
          },
        }}
        onClick={() => {
          handleClick();
        }}
        ref={params.refPlayer}
        style={{
          borderRadius: '10px',
          maxHeight:
            params.videoPlayerContainerRef?.current?.getBoundingClientRect()
              .height,
        }}
        className={'videoPlayerWrapper'}
        width={wideScreenMode ? 'auto' : 'fit-content'}
        height={wideScreenMode ? '100%' : 'fit-content'}
        url={params.activeVideo?.file_url}
        playbackRate={params.playbackRate}
        progressInterval={1}
        playing={isPlaying}
        onProgress={params.handleProgress}
        onError={params.handleError}
        // onEnded={startNextVideo}
        onBufferEnd={params.onBufferEndReady}
        onReady={params.onPlayerReady}
        volume={params.volume}
      />
      {reelsModeOn && (
        <ReelsScreenRecorderV3 playerState={params.playerState} />
      )}
    </div>
  );
};
export default PlayerWrapper;
