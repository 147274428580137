import { createSlice } from '@reduxjs/toolkit';

import { personaPercentilesType } from 'types/types';

type InitialStateType = {
  teamPlayerStatsState_stats: 'init' | 'loading' | 'loaded' | 'error';
  teamPlayerStatsMatches_stats: any; // консолидированые данные по сезонам/турниру/играм игрока для статистики
  timeOnField_stats: number;
  timeOnFieldByPosition_stats: any;

  tableMatches_stats: any[]; // список игр, отображаемых в окне показа для фильтрации статистики
  selectedRowKeys_stats: string[] | undefined; // список id игр, выбранных в таблице статистики
  appliedMatches_stats: string[]; // список id игр, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  checkedSeasons_stats: string[]; // список id выбранных сезонов в модалке статистики
  appliedSeasons_stats: string[]; // список id сезонов, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  appliedTournaments_stats: string[]; // список id турниров, по которым была применена фильтация. записывается после закрытия модалки по кнопке apply
  selectedTournaments_stats: string[]; // список id выбранных турниров в модалке статистики

  expandedSeasons_stats: string[]; // список раскрытых сезонов в дереве модалки

  selectedLastMatch_stats: number | string | null;
  appliedLastMatch_stats: number | string | null;
  selectedMatches_stats: any[];
  selectedSeasons_stats: string[];
  loadingPercentiles_stats: boolean;
  personaPercentiles_stats: personaPercentilesType[];
  teamPlayerStats_stats: any[];
  statsState_stats: 'init' | 'loading' | 'loaded' | 'error';
  initializeGamesCommand_stats: boolean;

  positionsDurations_stats: any;
  positionsLoading_stats: boolean;
  positions_stats: any[];
  selectedPosition_stats: string | null;
  selectedMatchesStats_stats: any;
  selectedMultitoolMetric_stats: string | null;

  multitoolEventsLoading: boolean;
  multitoolEvents: any[];
  fromFieldEventsDetails: any[];
  toFieldEventsDetails: any[];
  selectedFromFieldBlocks: number[];
  selectedToFieldBlocks: number[];
  selectedEventsFieldBlocks: number[];

  displayedComets: any[];
  matchedPointEvents: any[];
  selectedStat: any | null;
  selectedFromFieldCells: number[];
  selectedToFieldCells: number[];

  selectedMainFieldCells: number[];
  selectedEventsOrComets: any[];
  selectedMetricFilters: any[];
};
const initialState: InitialStateType = {
  teamPlayerStatsState_stats: 'init',
  teamPlayerStatsMatches_stats: [],
  timeOnField_stats: 0,
  timeOnFieldByPosition_stats: {},

  tableMatches_stats: [],
  checkedSeasons_stats: [],
  expandedSeasons_stats: [],

  selectedLastMatch_stats: null,
  appliedLastMatch_stats: null,
  selectedMatches_stats: [],
  loadingPercentiles_stats: false,
  personaPercentiles_stats: [],
  statsState_stats: 'init',
  selectedSeasons_stats: [],
  selectedTournaments_stats: [],
  appliedMatches_stats: [],
  appliedSeasons_stats: [],
  appliedTournaments_stats: [],
  teamPlayerStats_stats: [],
  selectedRowKeys_stats: undefined,
  initializeGamesCommand_stats: false,
  positionsDurations_stats: [],
  positionsLoading_stats: false,
  positions_stats: [],
  selectedPosition_stats: null,
  selectedMatchesStats_stats: [],
  selectedMultitoolMetric_stats: null,

  selectedStat: null,
  multitoolEventsLoading: false,
  multitoolEvents: [],
  fromFieldEventsDetails: [],
  toFieldEventsDetails: [],
  selectedFromFieldBlocks: [],
  selectedToFieldBlocks: [],
  selectedEventsFieldBlocks: [],
  displayedComets: [],
  matchedPointEvents: [],
  selectedFromFieldCells: [],
  selectedToFieldCells: [],
  selectedMainFieldCells: [],
  selectedEventsOrComets: [],
  selectedMetricFilters: [],
};
export const teamPlayerStatsSectionReducer = createSlice({
  name: 'personaReducer',
  initialState: initialState,
  reducers: {
    setTeamPlayerStats_stats(state, action) {
      state.teamPlayerStats_stats = action.payload;
    },
    setTeamPlayerStatsState_stats(state, action) {
      state.teamPlayerStatsState_stats = action.payload;
    },
    setTimeOnField_stats(state, action) {
      state.timeOnField_stats = action.payload;
    },
    setTimeOnFieldByPosition_stats(state, action) {
      state.timeOnFieldByPosition_stats = action.payload;
    },
    setStatsState_stats(state, action) {
      state.statsState_stats = action.payload;
    },
    setSelectedLastMatch_stats(state, action) {
      state.selectedLastMatch_stats = action.payload;
    },
    setTeamPlayerMatches_stats(state, action) {
      state.teamPlayerStatsMatches_stats = action.payload;
    },
    setTableMatches_stats(state, action) {
      state.tableMatches_stats = action.payload;
    },
    setSelectedMatches_stats(state, action) {
      state.selectedMatches_stats = action.payload;
    },
    toggleSelectedMatch_stats(state, action) {
      if (state.selectedMatches_stats.includes(action.payload)) {
        state.selectedMatches_stats = state.selectedMatches_stats.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedMatches_stats.push(action.payload);
      }
    },
    setPesonaPercentiles_stats(state, action) {
      state.personaPercentiles_stats = action.payload;
    },
    setSelectedSeasons_stats(state, action) {
      state.selectedSeasons_stats = action.payload;
    },
    setSelectedTournaments_stats(state, action) {
      state.selectedTournaments_stats = action.payload;
    },
    setAppliedMatches_stats(state, action) {
      state.appliedMatches_stats = action.payload;
    },
    setAppliedSeasons_stats(state, action) {
      state.appliedSeasons_stats = action.payload;
    },
    setAppliedTournaments_stats(state, action) {
      state.appliedTournaments_stats = action.payload;
    },
    setAppliedLastMatches_stats(state, action) {
      state.appliedLastMatch_stats = action.payload;
    },
    setSelectedRowKeys_stats(state, action) {
      state.selectedRowKeys_stats = action.payload;
    },
    setInitializeGamesCommand_stats(state, action) {
      state.initializeGamesCommand_stats = action.payload;
    },
    setCheckedSeasons_stats(state, action) {
      state.checkedSeasons_stats = action.payload;
    },
    setExpandedSeasons_stats(state, action) {
      state.expandedSeasons_stats = action.payload;
    },
    setLoadingPercentiles_stats(state, action) {
      state.loadingPercentiles_stats = action.payload;
    },
    setPositionsDurations_stats(state, action) {
      state.positionsDurations_stats = action.payload;
    },
    setpositionsLoading_stats(state, action) {
      state.positionsLoading_stats = action.payload;
    },
    setPositions_stats(state, actions) {
      state.positions_stats = actions.payload;
    },
    setSelectedPosition_stats(state, action) {
      state.selectedPosition_stats = action.payload;
    },
    setSelectedMatchesStats_stats(state, action) {
      state.selectedMatchesStats_stats = action.payload;
    },
    setSelectedMultitoolMetric_stats(state, action) {
      state.selectedMultitoolMetric_stats = action.payload;
    },
    setMultitoolEvents(state, action) {
      state.multitoolEvents = action.payload;
    },
    setFromFieldEventsDetails(state, action) {
      state.fromFieldEventsDetails = action.payload;
    },
    setToFieldEventsDetails(state, action) {
      state.toFieldEventsDetails = action.payload;
    },
    setSelectedFromFieldBlocks(state, action) {
      state.selectedFromFieldBlocks = action.payload;
    },
    setSelectedToFieldBlocks(state, action) {
      state.selectedToFieldBlocks = action.payload;
    },
    setSelectedEventsFieldBlocks(state, action) {
      state.selectedEventsFieldBlocks = action.payload;
    },
    setDisplayedComets(state, action) {
      state.displayedComets = action.payload;
    },
    setMatchedPointEvents(state, action) {
      state.matchedPointEvents = action.payload;
    },
    setSelectedStat(state, action) {
      state.selectedStat = action.payload;
    },
    setSelectedFromFieldsCells(state, action) {
      state.selectedFromFieldCells = action.payload;
    },
    setSelectedToFieldsCells(state, action) {
      state.selectedToFieldCells = action.payload;
    },
    setSelectedMainFieldsCells(state, action) {
      state.selectedMainFieldCells = action.payload;
    },
    setMultitoolEventsLoading(state, action) {
      state.multitoolEventsLoading = action.payload;
    },
    setSelectedEventsOrComets(state, action) {
      state.selectedEventsOrComets = action.payload;
    },
    setSelectedMetricFilters(state, action) {
      state.selectedMetricFilters = action.payload;
    },
  },
});
