import React from 'react';

import { Tooltip } from 'antd';

const EventsCloud = ({
  lastXEvents,
  bounds,
}: {
  lastXEvents: any;
  bounds: any;
}) => {
  return (
    <>
      {lastXEvents &&
        lastXEvents['success']?.map((evt: any, index: number) => (
          <Tooltip title={evt.name} placement="top" key={index}>
            <div
              key={index}
              id={evt.id}
              className="event-dot success enabledClickable small"
              style={{
                left: evt.x * bounds.width - 2,
                top: evt.y * bounds.height - 2,
              }}
            />
          </Tooltip>
        ))}
      {lastXEvents &&
        lastXEvents['fail']?.map((evt: any, index: number) => (
          <Tooltip title={evt.name} placement="top" key={index}>
            <div
              id={evt.id}
              className="event-dot fail enabledClickable small"
              style={{
                left: evt.x * bounds.width - 2,
                top: evt.y * bounds.height - 2,
              }}
            />
          </Tooltip>
        ))}
    </>
  );
};
export default EventsCloud;
