import React, { FC, ForwardedRef, forwardRef } from 'react';

const ItemWrapper: FC<any> = forwardRef<ForwardedRef<any>, any>(
  (props, ref: any) => {
    return (
      <div {...props} ref={ref}>
        {props.children}
      </div>
    );
  },
);
export default ItemWrapper;
