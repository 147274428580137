import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';

import { GetSingleSeasonSelected_stats } from './persona.actions';

const {
  setMultitoolEvents,
  setFromFieldEventsDetails,
  setToFieldEventsDetails,
  setMultitoolEventsLoading,
  setSelectedEventsOrComets,
  setMatchedPointEvents,
} = teamPlayerStatsSectionReducer.actions;

export const GetMultitoolEvents =
  (
    metric: string,
    playerId: string,
    games: any = [],
    selectedPosition_stats: string | null = '',
    selectedMetricFilters: any,
    abortSignal: AbortSignal,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setMultitoolEventsLoading(true));
      const seasonId = await dispatch(GetSingleSeasonSelected_stats());
      const response = await EventsAPI.getEventDetailsForMultitool({
        metric: metric,
        playerId: playerId,
        ids: [],
        games: games,
        selectedSeason: seasonId,
        selectedPosition: selectedPosition_stats || '',
        selectedMetricFilters,
        abortSignal,
      });
      if (response) {
        dispatch(setMultitoolEvents(response.data.events));
        dispatch(setFromFieldEventsDetails(response.data.fromEvents));
        dispatch(setToFieldEventsDetails(response.data.toEvents));
        dispatch(setMultitoolEventsLoading(false));
      }
    } catch (e) {
      // dispatch(setMultitoolEventsLoading(false));
    }
  };

export const GetMultitoolEventsAndOpenVideoPlayer =
  (
    metric: string,
    playerId: string,
    games: any = [],
    selectedPosition_stats: string | null = '',
    selectedMetricFilters: any,
  ): ThunkAction<Promise<any>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      const seasonId = await dispatch(GetSingleSeasonSelected_stats());
      const response = await EventsAPI.getEventDetailsForMultitool({
        metric: metric,
        playerId: playerId,
        ids: [],
        games: games,
        selectedSeason: seasonId,
        selectedPosition: selectedPosition_stats || '',
        selectedMetricFilters,
      });
      if (response) {
        return [...response.data.events.map((evt: any) => evt.id)];
      }
    } catch (e) {
      // dispatch(setMultitoolEventsLoading(false));
    }
  };

export const AddSelectedEventOrComet =
  (eventId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { selectedEventsOrComets } = getState().teamPlayerStatsSectionReducer;
    !selectedEventsOrComets.includes(eventId) &&
      dispatch(setSelectedEventsOrComets([...selectedEventsOrComets, eventId]));
  };

export const RemoveSelectedEventOrComet =
  (eventId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { selectedEventsOrComets } = getState().teamPlayerStatsSectionReducer;
    dispatch(
      setSelectedEventsOrComets(
        selectedEventsOrComets.filter((evt) => evt !== eventId),
      ),
    );
  };

export const ToggleMultipleCells =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { selectedFromFieldCells, multitoolEvents, fromFieldEventsDetails } =
      getState().teamPlayerStatsSectionReducer;
    const finalEventIds = multitoolEvents.filter(
      (evt: any) =>
        selectedFromFieldCells
          .map((cellId: number) => fromFieldEventsDetails[cellId])
          .filter(
            (cell: any) =>
              cell && cell?.events && cell?.events?.includes(evt.id),
          ).length,
    );
    dispatch(setMatchedPointEvents(finalEventIds));
  };
