import React from 'react';

import { Flex } from 'antd';

import MatchesBlock from '../MatchesBlock';
import TeamStatisticsBlockV2 from '../TeamStatisticsV2';

const TeamDataBlock = () => {
  return (
    <Flex
      style={{
        width: '100%',
      }}
      justify="space-between"
      gap={24}
    >
      <MatchesBlock />
      <TeamStatisticsBlockV2 />
    </Flex>
  );
};
export default TeamDataBlock;
