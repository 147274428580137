import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';

import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_setIntermediateActivitiesAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
} from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { ClubsAPI } from 'api/clubs';
import ContentContainerV2 from 'components/ContentContainerV2';
import PlayerPageController from 'components/PlayerPageController';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

const PlayerPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  const dispatch = useAppDispatch();
  const { resetReelsState } = reelsReducer.actions;
  const { playerId } = useParams();
  const { setOpenedPlayer } = teamPlayerReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    setTeamPlayerLoading,
    clearAllGameCoordinates,
    setPositions,
    setTopPlayerRole,
    setPositionsDurations,
  } = teamPlayerReducer.actions;
  const {
    setPositions_stats,
    setSelectedEventsOrComets,
    setSelectedFromFieldsCells,
    setSelectedToFieldsCells,
    setSelectedStat,
  } = teamPlayerStatsSectionReducer.actions;
  const { setStatsCustomGameList } = teamReducer.actions;
  const { resetEditMode, setCreateEpisodeMode } = playerReducerV2.actions;

  useEffect(() => {
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetReelsState());
    dispatch(AC_SetPlayerLoadingAction(false));
    dispatch(setStyleModeAction('light'));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    dispatch(resetReelsState());
    dispatch(setOpenedPlayer(null));
    dispatch(setCreateEpisodeMode(false));
    dispatch(clearAllGameCoordinates());
    dispatch(setPositions([]));
    dispatch(setPositions_stats([]));
    dispatch(setTopPlayerRole([]));
    dispatch(setPositionsDurations({}));
    dispatch(setSelectedEventsOrComets([]));
    dispatch(setSelectedFromFieldsCells([]));
    dispatch(setSelectedToFieldsCells([]));
    dispatch(setSelectedStat(''));
    dispatch(AC_setIntermediateActivitiesAction([]));
    window.document.body.setAttribute('data-theme', 'light');
    dispatch(AC_SetPlayerLoadingAction(false));
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  useEffect(() => {
    if (playerId) {
      dispatch(setTeamPlayerLoading(true));
      ClubsAPI.getPlayer(playerId).then((response) => {
        dispatch(setOpenedPlayer(response.data));
        dispatch(setTeamPlayerLoading(false));
        const metaElement = document.getElementById('meta-top-name');
        const titleElement = document.getElementById('title-top-name');
        if (metaElement && titleElement) {
          metaElement.setAttribute(
            'content',
            `${response.data.first_name} ${response.data.last_name} | ${response.data.team.name}`,
          );
          titleElement.innerHTML = `${response.data.first_name} ${response.data.last_name} | ${response.data.team.name}`;
        }
      });
    }
  }, [playerId]);
  return (
    <ContentContainerV2
      pageTitle={''}
      page={'playerProfile'}
      showNavigationPanel={showNavigationPanel}
      style={{
        padding: '0 16px',
        maxWidth: 1648,
        minWdth: 1280,
      }}
    >
      <PlayerPageController />
    </ContentContainerV2>
  );
};
export default PlayerPage;
