import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import AverageStatValueTag from 'components/PlayerPageController/PlayerStatisticsBlock/AverageStatValueTag';
import { AppStateType } from 'reducers';

const MultitoolAverageStatValueTag = (props: any) => {
  const {
    selectedStat,
    selectedFromFieldCells,
    selectedToFieldCells,
    displayedComets,
    matchedPointEvents,
    selectedEventsOrComets,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const [localHideValue, setLocalHideValue] = useState<boolean>(
    props.hideValue,
  );
  useEffect(() => {
    setLocalHideValue(props.hideValue);
  }, [props.hideValue]);
  useEffect(() => {
    if (props.stat?.key === selectedStat?.key) {
      if (
        selectedEventsOrComets.length > 0 ||
        selectedFromFieldCells.length > 0 ||
        selectedToFieldCells.length > 0
      ) {
        setLocalHideValue(true);
      } else {
        setLocalHideValue(false);
      }
    } else {
      setLocalHideValue(false);
    }
  }, [
    selectedStat,
    selectedFromFieldCells,
    selectedToFieldCells,
    selectedEventsOrComets,
    matchedPointEvents,
    displayedComets,
  ]);
  return (
    <AverageStatValueTag
      stat={props.stat}
      loadingPercentiles={props.loadingPercentiles}
      selectedMetricFilters={props.selectedMetricFilters}
      selectedPositions={props.selectedPositions}
      timeOnField={props.timeOnField}
      timeOnFieldByPosition={props.timeOnFieldByPosition}
      hideValue={localHideValue}
    />
  );
};
export default MultitoolAverageStatValueTag;
