import { ThunkAction } from 'redux-thunk';

import { ClubsAPI } from 'api/clubs';
import { EventsAPI } from 'api/events';
import { RegistriesAPI } from 'api/registries';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

const {
  setSelectedLastMatch,
  setTeamSeasonsList,
  setTeamTournamentSeasonTable,
  setTeamTournamentSelectedSeason,
  setGameRowPerTeamStats,
  setTableMatchesLoading,
  setTableMatches,
  setSelectedRowKeys,
  setCheckedSeasons,
  setSelectedTournaments,
  setSelectedMatches,
  setExpandedSeasons,
  setAppliedSeasons,
  setTeamMatches,
} = teamReducer.actions;

export const SetSelectedTeamLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetTournamentTable =
  (
    tournamentId: number,
    seasonId: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const teamResponse = await RegistriesAPI.getScoreTableV2(
      tournamentId,
      seasonId,
    );
    dispatch(setTeamTournamentSeasonTable(teamResponse.data));
  };

export const InitializeTeamSeasons =
  (teamId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const response = await ClubsAPI.getTeamSeasons(teamId, true);
    dispatch(setTeamSeasonsList(response.data));
    if (response.data.length) {
      dispatch(
        GetTournamentTable(response.data[0].tournament.id, response.data[0].id),
      );
      dispatch(
        setTeamTournamentSelectedSeason(
          `${response.data[0].tournament.id}-${response.data[0].id}`,
        ),
      );
    }
  };

export const GetMatchStatsPerTeam =
  (
    matchId: string,
    teamId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const response = await EventsAPI.getGameStatsForTeam(matchId, teamId);
    dispatch(
      setGameRowPerTeamStats({ matchId: matchId, stats: response.data }),
    );
  };

export const GetTeamAllMatches =
  (teamId: string): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setTableMatchesLoading('loading'));
      try {
        const data = await ClubsAPI.getTeamGamesWithSeasons(teamId, true);
        dispatch(setTeamMatches(data.data));
        if (data.data.length) {
          const firstSeasonMatchIds = data.data[0]['leagues'][0]['matches'].map(
            (match: any) => match.id,
          );
          dispatch(
            setTableMatches(
              data.data[0]['leagues'][0]['matches'].map((match: any) => {
                return { key: match.id, gameDetails: match };
              }),
            ),
          );
          dispatch(setSelectedRowKeys(firstSeasonMatchIds));
          dispatch(
            setCheckedSeasons([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(
            setSelectedTournaments([
              `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
            ]),
          );
          dispatch(setSelectedMatches(data.data[0]['leagues'][0]['matches']));
          dispatch(setExpandedSeasons([`0-${data.data[0].name}`]));
          dispatch(setAppliedSeasons(data.data[0].leagues[0]?.season_id));
        }
        dispatch(setTableMatchesLoading('loaded'));
      } catch (e) {
        dispatch(setTableMatchesLoading('error'));
      }
    } catch (e) {
      console.log(e);
    }
  };
