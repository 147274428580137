import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { SendLoginRequestAction } from 'actions/auth.actions';
import { AuthAPI } from 'api/authentication';
import WarningSign from 'assets/img/warningSign.svg';
import './index.css';
import InputWithClear from 'components/InputWithClear';
import { AppStateType } from 'reducers';

const RegistrationPage = () => {
  const [email, setEmail] = useState('');
  const [emailIsOnFocus, setEmailIsOnFocus] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [password, setPassword] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passwordIsOnFocus, setPasswordIsOnFocus] = useState(false);

  const [agreedEula, setAgreedEula] = useState(false);
  const [warningEulaVisible, setWarningEulaVisible] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [club, setClub] = useState<number | null>(null);
  const { linkId } = useParams();
  const navigate = useNavigate();
  const [registrationLinkError, setRegistrationLinkError] = useState<
    string | null
  >(null);
  useEffect(() => {
    const checkLink = async () => {
      if (linkId) {
        const linkCheckResponse = await AuthAPI.checkRegistrationLink(linkId);
        if (linkCheckResponse.status === 200) {
          setClub(linkCheckResponse.data.club);
        } else {
          setRegistrationLinkError(
            'Ссылка на регистрацию не найдена или невалидна. Пожалуйста, запросите новую ссылку.<',
          );
          setTimeout(() => {
            setRegistrationLinkError(null);
            navigate('/login');
          }, 3000);
        }
      } else {
        navigate('/login');
      }
    };
    checkLink().then();
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  const { loggedIn, redirectTo } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  useEffect(() => {
    const localToken = localStorage.getItem('authtoken');
    if (localToken) {
      window.location.href = redirectTo;
    }
  }, [loggedIn, redirectTo]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!agreedEula) {
      setWarningEulaVisible(true);
      setTimeout(() => {
        setWarningEulaVisible(false);
      }, 4000);
      event.preventDefault();
    }
    if (!emailIsValid || !passwordIsValid) {
      event.preventDefault();
    }
    if (linkId) {
      const response = await AuthAPI.registerByLink(email, password, linkId);

      if (response.status === 200) {
        //@ts-ignore
        dispatch(SendLoginRequestAction(email, password));
        navigate('/login');
      } else if (
        response.status === 400 &&
        response.data.error === 'Email already used'
      ) {
        setRegistrationLinkError(
          'Данный email уже занят. Пожалуйста, выберите новый.',
        );
        setTimeout(() => {
          setRegistrationLinkError(null);
        }, 3000);
      }
    }
  };

  const updateFocus = () => {
    setEmailIsOnFocus(document.activeElement === emailRef.current);
    setPasswordIsOnFocus(document.activeElement === passwordRef.current);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [warningEulaVisible, emailIsOnFocus]);
  useEffect(() => {
    updateFocus();
  }, [email, password]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const eulaRef = useRef<any>(null);
  const getTopOffset = (fieldRef: any) => {
    if (fieldRef.current) {
      return fieldRef?.current?.getBoundingClientRect().top - 8;
    }
  };
  const getLeftOffset = (fieldRef: any) => {
    return fieldRef?.current?.offsetLeft + fieldRef.current.clientWidth + 20;
  };
  const [t] = useTranslation();
  return (
    <div className="loginFormContainer">
      <div className="loginFormHeader">{t('Registration')}</div>
      <div className="loginForm">
        <form name="registrationForm">
          <div>
            <InputWithClear
              isRequired={true}
              valueController={{ value: email, setValue: setEmail }}
              fieldType={'email'}
              fieldName={'email'}
              labelText={t('Enter email:')}
              validate={true}
            />
          </div>
          <div>
            <InputWithClear
              isRequired={true}
              valueController={{ value: password, setValue: setPassword }}
              fieldType={'password'}
              fieldName={'password'}
              labelText={t('Make a new password:')}
              clearIcon={false}
              validate={true}
            />
          </div>
          <div ref={eulaRef} className="eulaAcceptanceContainer">
            <input
              checked={agreedEula}
              required={true}
              onClick={() => setAgreedEula((prev) => !prev)}
              type="checkbox"
              className={warningEulaVisible ? 'warningCheckbox' : ''}
              id="eulaAccepted"
              name="eulaAccepted"
            />
            <label>
              {t('I agree that my')}
              <a href="#" className="eulaLink">
                {t('personal data will be processed')}
              </a>
            </label>
            {warningEulaVisible && (
              <div
                className="warningFieldRequired"
                style={{
                  top: `${getTopOffset(eulaRef)}px`,
                  left: `${getLeftOffset(eulaRef)}px`,
                }}
              >
                <img src={WarningSign} />
                <div>{t('Required field')}</div>
              </div>
            )}
          </div>
          <div className="buttonContainer">
            <button onClick={handleSubmit}>{t('Register')}</button>
          </div>
          <div className="linkContainer">
            {t('Already have an account?')}{' '}
            <Link to="/login">{t('Enter')}</Link>
          </div>
        </form>
      </div>
      <ReactModal
        overlayClassName="modalOverlay"
        className={'teamsAddModal warning'}
        isOpen={registrationLinkError !== null}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onRequestClose={() => {}}
        shouldCloseOnEsc={false}
      >
        <div className="">{registrationLinkError}</div>
      </ReactModal>
    </div>
  );
};
export default RegistrationPage;
