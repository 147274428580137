import React, { useEffect, useRef, useState } from 'react';

import { Flex, Tooltip } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useSelector } from 'react-redux';

import {
  AddSelectedEventOrComet,
  RemoveSelectedEventOrComet,
} from 'actions/playerMultitool.actions';
import { ReactComponent as CloseIcon } from 'assets/img/SmallCloseCrossWhite.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';

const Comet = ({
  comet,
  bounds,
  asPoint,
  selectionRectangle,
  isSelectedState,
  selectedCount,
}: {
  comet: any;
  bounds: any;
  asPoint: boolean;
  selectionRectangle: any;
  isSelectedState: boolean;
  selectedCount: number;
}) => {
  const { selectedEventsOrComets } = useSelector(
    (state: AppStateType) => state.teamPlayerStatsSectionReducer,
  );
  const circle1Ref = useRef<any>(null);
  const circle2Ref = useRef<any>(null);
  const [angle, setAngle] = useState(0);
  const [isSelected, setIsSelected] = useState(
    isSelectedState ? 'selected' : '',
  );
  const [distance, setDistance] = useState<any>(0);
  const [rightCenter, setRightCenter] = useState<any>({ x: 0, y: 0 });
  const [boundsRect, setBoundsRect] = useState<any>({ x: 0, y: 0 });
  const dispatch = useAppDispatch();
  const connectCircles = () => {
    const circle1 = circle1Ref.current;
    const circle2 = circle2Ref.current;
    const b = bounds.current.getBoundingClientRect();
    if (!circle1 || !circle2 || !b) {
      return;
    }
    setBoundsRect(b);

    const rect1 = circle1.getBoundingClientRect();
    const rect2 = circle2.getBoundingClientRect();
    // Calculate the centers of the circles
    const x1 = rect1.left + rect1.width / 2;
    const y1 = rect1.y + rect1.height / 2;
    const x2 = rect2.left + rect2.width / 2;
    const y2 = rect2.y + rect2.height / 2;
    // Calculate the distance and angle
    const dx = x2 - x1;
    const dy = y2 - y1;
    const d = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
    setDistance(d);
    setRightCenter({ x: x2, y: y2 });
    const an = Math.atan2(dy, dx);
    setAngle(an / (Math.PI / 180));
  };
  useEffect(() => {
    connectCircles();
    window.addEventListener('resize', connectCircles);
    return () => {
      window.removeEventListener('resize', connectCircles);
    };
  }, [circle1Ref, circle2Ref]);
  useEffect(() => {
    if (!asPoint && selectionRectangle?.x && selectionRectangle?.y) {
      if (
        comet.coords.start.x * boundsRect.width >= selectionRectangle.x &&
        comet.coords.start.x * boundsRect.width <=
          selectionRectangle.x + selectionRectangle.width &&
        comet.coords.start.y * boundsRect.height >= selectionRectangle.y &&
        comet.coords.start.y * boundsRect.height <=
          selectionRectangle.y + selectionRectangle.height
      ) {
        // setIsSelected('selected');
        if (isSelected !== 'selected') {
          dispatch(AddSelectedEventOrComet(comet.id));
        }
      } else {
        if (isSelected === 'selected') {
          dispatch(RemoveSelectedEventOrComet(comet.id));
        }
        // setIsSelected('opaque');
      }
    } else {
      // if (isSelected !== 'selected') {
      //   dispatch(RemoveSelectedEventOrComet(comet.id));
      // }
      // setIsSelected('');
    }
  }, [selectionRectangle, boundsRect, isSelected]);
  useEffect(() => {
    if (!asPoint && selectionRectangle.x && selectionRectangle.y) {
      if (isSelectedState) {
        setIsSelected('selected');
      } else {
        setIsSelected('opaque');
      }
    } else {
      if (selectedCount === 0) {
        setIsSelected('');
      }
    }
  }, [isSelectedState, selectionRectangle, selectedEventsOrComets]);
  const toggleCometSelection = (e: any) => {
    e.stopPropagation();
    if (isSelected !== 'selected') {
      setIsSelected('selected');
      dispatch(AddSelectedEventOrComet(comet.id));
    } else {
      dispatch(RemoveSelectedEventOrComet(comet.id));
      setIsSelected('');
    }
  };
  return (
    <div>
      <Tooltip>
        <Flex
          align="center"
          justify="center"
          ref={circle1Ref}
          className={`enabledClickable comet head ${comet.result} ${asPoint ? 'asPoint' : ''} ${isSelected}`}
          style={{
            left: `calc(${comet.coords.finish.x * 100}% - 4.5px)`,
            top: `calc(${comet.coords.finish.y * 100}% - 4.5px)`,
            zIndex: 20,
          }}
          onClick={toggleCometSelection}
        >
          {/* // do not remove */}
          <div></div>
        </Flex>
      </Tooltip>
      {comet.result === 'Fail' && (
        <div
          style={{
            position: 'absolute',
            left: `calc(${comet.coords.finish.x * 100}% - 2.5px)`,
            top: `calc(${comet.coords.finish.y * 100}% - 2.5px)`,
            zIndex: 50,
            height: 6,
            width: 6,
            display: 'flex',
            alignContent: 'center',
          }}
          className="enabledClickable"
          onClick={toggleCometSelection}
        >
          <CloseIcon />
        </div>
      )}
      <div
        ref={circle2Ref}
        className={`enabledClickable comet tail ${comet.result} ${asPoint ? 'asPoint' : ''} ${isSelected}`}
        style={{
          left: `calc(${comet.coords.start.x * 100}% - 2.5px)`,
          top: `calc(${comet.coords.start.y * 100}% - 2.5px)`,
        }}
        onClick={toggleCometSelection}
      ></div>
      <div
        className={`enabledClickable comet-tail ${comet.result} ${isSelected}`}
        style={{
          position: 'absolute',
          width: 8,
          borderBottomWidth: `${distance}px`,
          borderBottomStyle: 'solid',
          borderLeft: '2.5px solid transparent',
          borderRight: '2.5px solid transparent',
          height: 0,
          left: `calc(${rightCenter.x - 4 - boundsRect.left || 0}px)`,
          top: `calc(${rightCenter.y - boundsRect.top || 0}px)`,
          transformOrigin: '50% 0',
          transform: `rotate(${angle + 90}deg)`,
        }}
        onClick={toggleCometSelection}
      ></div>
    </div>
  );
};
export default Comet;
