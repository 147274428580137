import React, { useEffect, useState } from 'react';

import './index.css';

import { arrayMove } from '@dnd-kit/sortable';
import { ConfigProvider } from 'antd';
// import DirectoryTree from 'antd/es/tree/DirectoryTree';
import { produce } from 'immer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UpdateUserAction } from 'actions/auth.actions';
import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import {
  deleteFolderAction,
  getRootPlaylistsActionV2,
  updateFolderAction,
} from 'actions/playlistV2.async.actions';
import { PlaylistsAPI } from 'api/playlists';
import PlaylistsSkeleton from 'components/Skeletons/PlaylistsSkeleton';
import { MODES } from 'components/VideoListController';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';
import { generateUUID } from 'types/crypto';
// import { ConfigProvider } from 'utils/antd';
import DirectoryTree from 'utils/tree/DirectoryTree';

import FolderElement from './FolderElement';
import PlaylistElement from './PlaylistElement';
import { ReactComponent as Chevron } from '../../assets/img/icons/faChevronRight.svg';
import DeleteModal from '../modals/DeletePModal';
import MoveToFolderModal from '../modals/MoveToFolderModal';

const FoldersComponent = ({
  shareEpisodesCallable,
  setMovePlaylistToFolderOpen,
}: {
  shareEpisodesCallable: any;
  movePlaylistToFolderOpen: any;
  setMovePlaylistToFolderOpen: any;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openedPlaylistId, playlistsAndFolders } = useSelector(
    (state: AppStateType) => state.playlistReducerV2,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { AC_setPlaylistsAndFolders } = playlistReducerV2.actions;
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [renameFolderId, setRenameFolderId] = useState(null);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newFolderName, setNewFolderName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renameFolderHandler = (folder: any) => {
    const updatedFolder = {
      ...folder,
      name: newFolderName,
    };
    setRenameFolderId(null);
    dispatch(updateFolderAction(updatedFolder, folder.id));
    dispatch(getRootPlaylistsActionV2());
  };
  useEffect(() => {
    if (renameFolderId && inputRef.current) {
      //@ts-ignore
      inputRef.current!.focus({
        cursor: 'all',
      });
    }
  }, [renameFolderId]);
  const inputRef = React.useRef(null);
  const { showNotification } = notificationsReducer.actions;
  const deleteFolder = (folderId: string | null) => {
    if (folderId) {
      dispatch(
        showNotification({
          notificationParameters: [
            {
              id: generateUUID(),
              text: t('Folder was deleted'),
              // callbackName: 'removeFolder',
              userEpsiodeId: folderId,
            },
          ],
        }),
      );
      dispatch(deleteFolderAction(folderId));
      setDeleteFolderId(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleDragEnd(event: any, playlists: any, folderId: string) {
    const { active, over } = event;
    // const type = playlistsAndFolders.filter(el => el.id === active.id)[0].elementType;
    const type = 'playlist';
    const oldIndex = playlists.findIndex((i: any) => i.id === active.id);
    const newIndex = playlists.findIndex((i: any) => i.id === over.id);
    // const newType = playlistsAndFolders.filter(el => el.id === over.id)[0].elementType;
    const newType = 'playlist';
    if (
      type === 'playlist' &&
      newType === 'playlist' &&
      over &&
      active?.id !== over?.id
    ) {
      const activeElId = playlists.filter((el: any) => el.id === active.id)[0]
        .elId;
      const overElId = playlists.filter((el: any) => el.id === over.id)[0].elId;
      const payload: any = { target_event_id: activeElId };
      if (
        active.data.current.sortable.index > over.data.current.sortable.index
      ) {
        payload['new_sibling_id'] = overElId;
      } else {
        payload['new_parent_id'] = overElId;
      }
      const reorderedPlaylists = arrayMove(playlists, oldIndex, newIndex);
      const updatedState = produce(playlistsAndFolders, (draft) => {
        const update = (els: any, localDraft: any): any => {
          for (const [index, folderRow] of els.entries()) {
            if (folderRow.elId === folderId) {
              localDraft[0].playlists = reorderedPlaylists;
              return;
            } else {
              update(folderRow.children, localDraft[index].children);
            }
          }
        };
        update(playlistsAndFolders, draft);
        // return draft;
      });
      dispatch(AC_setPlaylistsAndFolders(updatedState));

      if (currentUser?.playlist_sorting === 'manual') {
        PlaylistsAPI.reorderPlaylist(activeElId, payload).then();
      } else {
        currentUser &&
          dispatch(
            UpdateUserAction(currentUser?.id, {
              id: currentUser?.id,
              playlist_sorting: 'manual',
            }),
          );
      }
    } else if (
      //@ts-ignore
      type === 'folder' &&
      //@ts-ignore
      newType === 'folder' &&
      over &&
      active?.id !== over?.id
    ) {
      const activeElId = playlistsAndFolders.filter(
        (el) => el.id === active.id,
      )[0].id;
      const overElId = playlistsAndFolders.filter((el) => el.id === over.id)[0]
        .id;
      const payload: any = { target_event_id: activeElId };
      if (
        active.data.current.sortable.index > over.data.current.sortable.index
      ) {
        payload['new_sibling_id'] = overElId;
      } else {
        payload['new_parent_id'] = overElId;
      }
      dispatch(
        AC_setPlaylistsAndFolders(
          arrayMove(playlistsAndFolders, oldIndex, newIndex),
        ),
      );
      PlaylistsAPI.reorderFolder(activeElId, payload);
    }
  }
  const [moveToFolderModalOpen, setMoveToFolderModalOpen] = useState<
    string | null
  >(null);
  const retrieveFolder = (
    iterable: any,
    newFolder: any,
    destinationFolder: string | null,
  ): any => {
    for (const element of iterable) {
      if (element.type === 'folder') {
        if (newFolder) {
          return newFolder;
        }
        if (element.key === moveToFolderModalOpen) {
          newFolder = {
            ...element,
            parent: destinationFolder,
          };
          return newFolder;
        }
        newFolder = retrieveFolder(
          element?.children || [],
          0,
          destinationFolder,
        );
      }
    }
    return newFolder;
  };
  const moveToFolderCallback = (destinationFolder: string | null) => {
    if (moveToFolderModalOpen) {
      let newFolder: any = undefined;

      newFolder = retrieveFolder(
        playlistsAndFolders,
        newFolder,
        destinationFolder,
      );

      const payload = {
        parent: newFolder.parent,
        name: newFolder.title,
        collapsed: newFolder.collapsed || true,
        // level: newFolder.level,
      };
      dispatch(updateFolderAction(payload, newFolder.key, true));
      setMoveToFolderModalOpen(null);
      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
    }
  };
  const [playlistForDeletionId, setPlaylistForDeletionId] = useState(null);

  const deletePlaylist = () => {
    if (playlistForDeletionId) {
      PlaylistsAPI.deletePlaylist(playlistForDeletionId).then(() => {
        setPlaylistForDeletionId(null);
        dispatch(getRootPlaylistsActionV2());
      });
    }
  };
  const { playerMode } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const [rendering, setRendering] = useState(false);
  useEffect(() => {
    if (playerMode === MODES.playlists) {
      setRendering(true);
      PlaylistsAPI.getPlaylists({ sorting: 'manual' })
        .then((response: any) => {
          dispatch(AC_setPlaylistsAndFolders(response.data.hierarchy));
          setExpandedKeys(response.data.expanded_keys);
        })
        .finally(() => {
          setRendering(false);
        });
    }
  }, [playerMode]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  return (
    <div
      className={'flex-column f-ga-8 gameListContainer'}
      style={{ margin: '4px 0px 0 0px' }}
    >
      {openedPlaylistId ? (
        <></>
      ) : (
        <>
          {rendering ? (
            <PlaylistsSkeleton />
          ) : (
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: 'transparent',
                  colorText: 'white',
                },
              }}
            >
              <DirectoryTree
                switcherIcon={<Chevron />}
                multiple
                selectable={false}
                icon={false}
                draggable={{
                  icon: false,
                }}
                expandedKeys={expandedKeys}
                onExpand={(localExpandedKeys, info) => {
                  setExpandedKeys(localExpandedKeys);
                  const updatedFolder = {
                    ...info.node,
                    name: info.node.title,
                    collapsed: !info.expanded,
                  };
                  dispatch(updateFolderAction(updatedFolder, info.node.key));
                }}
                titleRender={(node: any) =>
                  node.type === 'playlist' ? (
                    <PlaylistElement
                      key={`playlist-${node.key}`}
                      playlist={node}
                      movePlayToFolderCallback={setMovePlaylistToFolderOpen}
                      shareEpisodesCallable={shareEpisodesCallable}
                      deletePlaylistCallback={setPlaylistForDeletionId}
                    />
                  ) : (
                    <FolderElement
                      key={`playlist-${node.key}`}
                      folder={node}
                      shareEpisodesCallable={shareEpisodesCallable}
                      setDeleteFolderId={setDeleteFolderId}
                      moveToFolderCallback={setMoveToFolderModalOpen}
                    />
                  )
                }
                onDrop={(info: any) => {
                  // console.log(info);
                  if (
                    info.dragNode.type === 'playlist' &&
                    info.node.type === 'folder'
                  ) {
                    PlaylistsAPI.updatePlaylist(
                      info.dragNode.key,
                      undefined,
                      info.node.key,
                    ).then(() => {
                      dispatch(getRootPlaylistsActionV2());
                    });
                  } else if (
                    info.dragNode.type === 'playlist' &&
                    info.node.type === 'playlist'
                  ) {
                    const payload = {
                      target_event_id: info.dragNode.key,
                      new_previous_id:
                        info.dropPosition > 0 ? info.node.key : null,
                      new_parent_id: info.node.parent,
                    };
                    PlaylistsAPI.reorderPlaylist(
                      info.dragNode.key,
                      payload,
                    ).then(() => {
                      dispatch(getRootPlaylistsActionV2());
                    });
                  } else if (info.dragNode.type === 'folder') {
                    const payload: any = {
                      target_event_id: info.dragNode.key,
                      // new_parent_id: info.node.key,
                    };
                    if (info.dropToGap && info.node.type === 'playlist') {
                      payload.new_previous_id = null;
                      payload.new_parent_id = info.node.parent;
                    } else if (info.dropToGap && info.node.type === 'folder') {
                      payload.new_previous_id = info.node.key;
                    }
                    if (!info.dropToGap && info.node.type === 'folder') {
                      payload.new_parent_id = info.node.key;
                    } else {
                      payload.new_parent_id = info.node.parent;
                    }
                    PlaylistsAPI.reorderFolder(info.dragNode.key, payload).then(
                      () => {
                        dispatch(getRootPlaylistsActionV2());
                      },
                    );
                  }
                }}
                rootClassName="root-class"
                defaultExpandAll
                treeData={playlistsAndFolders}
              ></DirectoryTree>
            </ConfigProvider>
          )}
          {
            <MoveToFolderModal
              isOpen={moveToFolderModalOpen !== null}
              type={'folder'}
              folderId={moveToFolderModalOpen}
              handleOk={moveToFolderCallback}
              closeCallable={() => {
                setMoveToFolderModalOpen(null);
                dispatch(AC_SetPlayerHotkeysAllowedAction(true));
              }}
            />
          }
          <DeleteModal
            isOpen={Boolean(deleteFolderId)}
            handleOk={() => deleteFolder(deleteFolderId)}
            closeCallable={() => setDeleteFolderId(null)}
            title={t('Delete folder')}
            content={t('All playlists from this folder will be saved to root')}
          />
        </>
      )}
      <DeleteModal
        title={t('Delete playlist?')}
        content={t('Are you sure you want to delete this playlist?')}
        closeCallable={() => setPlaylistForDeletionId(null)}
        handleOk={deletePlaylist}
        isOpen={playlistForDeletionId !== null}
      />
    </div>
  );
};
export default FoldersComponent;
