import React, { useEffect, useRef, useState } from 'react';

import {
  ConfigProvider,
  Divider,
  Flex,
  Radio,
  Tooltip,
  Tree,
  TreeDataNode,
  TreeProps,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  FilterApply,
  GetSeasonPercentiles_stats,
  GetSingleSeasonSelected_stats,
} from 'actions/persona.actions';
import PositionsFilter from 'components/PlayerPageController/PlayerStatisticsBlock/PositionsFilter';
import { prepareTreeStructure } from 'components/PlayerPageController/PlayerStatisticsBlock/SettingPopover';
import { constructMatchesString } from 'components/PlayerPageController/PlayerStatisticsBlock/utils';
import { AppStateType } from 'reducers';
// import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

const SeasonsSelector = ({
  setSelectedSeason,
  playerProfileMode,
}: {
  setSelectedSeason: any;
  playerProfileMode: any;
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const {
    setSelectedTournaments_stats,
    setCheckedSeasons_stats,
    setExpandedSeasons_stats,
    setSelectedRowKeys_stats,
    setTableMatches_stats,
    setSelectedMatches_stats,
    setSelectedMatchesStats_stats,
    setSelectedLastMatch_stats,
    setSelectedEventsOrComets,
  } = teamPlayerStatsSectionReducer.actions;
  const {
    teamPlayerStatsMatches_stats,
    selectedLastMatch_stats,
    checkedSeasons_stats,
    expandedSeasons_stats,
    selectedTournaments_stats,
    selectedRowKeys_stats,
    positions_stats,
    positionsDurations_stats,
    positionsLoading_stats,
    selectedPosition_stats,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const [preparedTreeStructure, setPreparedTreeStructure] = useState<
    TreeDataNode[]
  >([]);
  const onLastMatchRadioChange = async (e: any) => {
    await dispatch(setCheckedSeasons_stats([]));
    await dispatch(setSelectedTournaments_stats([]));
    await dispatch(setSelectedEventsOrComets([]));
    await dispatch(setSelectedLastMatch_stats(e.target.value));
  };
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  useEffect(() => {
    setExpandedKeys(expandedSeasons_stats);
  }, [expandedSeasons_stats]);
  useEffect(() => {
    if (openedTeamPlayer) {
      setPreparedTreeStructure(
        prepareTreeStructure(teamPlayerStatsMatches_stats, expandedKeys),
      );
    }
  }, [openedTeamPlayer, teamPlayerStatsMatches_stats, expandedKeys]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayedSeasonValue, setDisplayedSeasonValue] = useState('');
  const onExpand = (data: any) => {
    dispatch(setExpandedSeasons_stats(data));
  };
  const checkIfSeasonIsSelected = async (tableMatches: any) => {
    const s = await dispatch(GetSingleSeasonSelected_stats());
    if (s) {
      setSelectedSeason(null);
      dispatch(
        setSelectedMatchesStats_stats(
          tableMatches.map((match: any) => match.key),
        ),
      );
    } else {
      dispatch(setSelectedMatchesStats_stats([]));
    }
    return s;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSelectChange = async (newSelectedRowKeys: React.Key[], _: any) => {
    dispatch(setSelectedRowKeys_stats(newSelectedRowKeys));
    dispatch(setSelectedLastMatch_stats(''));
    await dispatch(GetSingleSeasonSelected_stats());
  };
  const controllerRef = useRef<any>(null);
  const populateLastMatches = () => {
    if (selectedLastMatch_stats) {
      let matches: any[] = [];
      preparedTreeStructure.forEach((season: TreeDataNode) => {
        season.children?.forEach((child: any) => {
          child.data.forEach((match: any) => {
            matches.push({
              key: match.id,
              gameDetails: match,
              title: t('All games'),
            });
          });
        });
      });
      matches = matches.sort((a: any, b: any) =>
        dayjs(b.gameDetails.date).diff(dayjs(a.gameDetails.date)),
      );
      if (selectedLastMatch_stats === '5') {
        matches = matches.slice(0, 5);
      } else if (selectedLastMatch_stats === '10') {
        matches = matches.slice(0, 10);
      }
      dispatch(setTableMatches_stats(matches));
      dispatch(
        setSelectedRowKeys_stats(matches.map((match: any) => match.key)),
      );
      const controller: AbortController = new AbortController();
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      dispatch(
        FilterApply(
          t,
          'manual',
          matches.map((match: any) => match.key),
          playerId || '',
          controllerRef.current.signal,
          'multitool',
        ),
      );
      return () => {
        controller.abort();
      };
    }
  };
  const { playerId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gameWidth, setGameWidth] = useState<string | number>('100%');
  useEffect(() => {
    const call = async () => {
      const seasonId = await dispatch(GetSingleSeasonSelected_stats());
      if (playerId && seasonId !== 'manual') {
        dispatch(GetSeasonPercentiles_stats(playerId, seasonId));
      }
    };
    call().then();
  }, [selectedPosition_stats]);
  useEffect(() => {
    populateLastMatches();
  }, [selectedLastMatch_stats]);
  const helper = (checkedKeys: any, filter: boolean) => {
    let flMatches: any = [];
    checkedKeys
      .filter((key: any) => key.length > 3 && key.indexOf('-') !== -1)
      .forEach((key: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const split = key.split('-');
        let index;
        let seasonId;
        let LeagueId: any;
        if (split.length === 2) {
          [index, seasonId] = split;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [index, seasonId, LeagueId] = split;
        }
        const matches = preparedTreeStructure
          .filter((season: TreeDataNode) => {
            return season.key === `0-${seasonId}`;
          })[0]
          ?.children?.filter((child: any) => {
            return child.key === `1-${seasonId}-${LeagueId}`;
          })[0];
        if (matches) {
          flMatches = flMatches
            //@ts-ignore
            .concat(matches.data || [])
            .sort((a: any, b: any) => dayjs(b.date).diff(dayjs(a.date)));
        }
      });
    const tableMatches = flMatches.map((match: any) => {
      return {
        key: match.id,
        gameDetails: match,
      };
    });
    dispatch(setTableMatches_stats(tableMatches));
    onSelectChange(
      flMatches.map((match: any) => match.id),
      filter,
    );
    dispatch(setSelectedMatches_stats(flMatches));
    checkIfSeasonIsSelected(tableMatches).then();
  };
  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    dispatch(setSelectedLastMatch_stats(null));
    dispatch(setSelectedEventsOrComets([]));
    if (Array.isArray(checkedKeys)) {
      helper(checkedKeys, true);
      dispatch(
        setSelectedTournaments_stats(
          checkedKeys.filter((el: any) => el.split('-').length > 2),
        ),
      );
      dispatch(setCheckedSeasons_stats(checkedKeys));
    } else {
      console.log('checkedKeys is not an array');
    }
  };
  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    if (wrapperRef.current) {
      // setGameWidth(wrapperRef?.current?.getBoundingClientRect().width - 60);
    }
  }, [playerProfileMode]);
  return (
    <Flex
      ref={wrapperRef}
      vertical
      style={{
        background: 'white',
        borderRadius: 12,
        maxWidth: gameWidth,
      }}
    >
      <Flex
        style={{ marginLeft: 12, marginRight: 12 }}
        justify="space-between"
        align="center"
      >
        <Flex className="elipsis-text seasons-title-block" align="center">
          <Tooltip
            title={`${
              selectedTournaments_stats.length === 1
                ? selectedTournaments_stats[0].split('-')[1]
                : ''
            } ${constructMatchesString({
              selectedRowKeys: selectedRowKeys_stats,
              t,
              selectedTournaments: selectedTournaments_stats,
            })}`}
          >
            {selectedTournaments_stats.length === 1
              ? selectedTournaments_stats[0].split('-')[1]
              : ''}
            &nbsp;
            {constructMatchesString({
              selectedRowKeys: selectedRowKeys_stats,
              t,
              selectedTournaments: selectedTournaments_stats,
            })}
          </Tooltip>
        </Flex>
        <Flex align="center" gap={8} className="select-placeholder">
          <ConfigProvider
            theme={{
              token: {
                paddingSM: 0,
              },
            }}
          >
            <PositionsFilter
              positions={
                openedTeamPlayer
                  ? openedTeamPlayer.team.sport !== 2
                    ? positions_stats
                    : []
                  : []
              }
              positionsDurations={positionsDurations_stats}
              positionsLoading={positionsLoading_stats}
              mode={'multitool'}
            />
          </ConfigProvider>
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Flex
        gap={8}
        style={{
          paddingTop: 12,
          paddingBottom: 8,
          paddingLeft: 4,
          paddingRight: 4,
        }}
        vertical
      >
        <Radio.Group
          onChange={onLastMatchRadioChange}
          value={selectedLastMatch_stats}
        >
          <Flex gap={0} vertical style={{ marginLeft: 12 }}>
            <Radio.Button className="personMatchCountSelect" value="5">
              {t('Last 5 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="10">
              {t('Last 10 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="all">
              {t('All matches')}
            </Radio.Button>
          </Flex>
        </Radio.Group>
        <Divider style={{ margin: 0, height: 10 }} />
        <Tree
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
          className={`treeSelect ${preparedTreeStructure.length > 1 ? 'with-border' : ''}`}
          checkable
          selectable={false}
          blockNode
          checkedKeys={checkedSeasons_stats}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          onCheck={onCheck}
          treeData={preparedTreeStructure}
        />
      </Flex>
    </Flex>
  );
};
export default SeasonsSelector;
