import React, { useState } from 'react';

import { ConfigProvider, Divider, Flex, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import MetricFilter from 'components/PlayerPageController/PlayerStatisticsBlock/MetricFilter';
import { teamReducer } from 'reducers/team.reducer';

import { ReactComponent as DownOutlinedBlack } from '../../../assets/img/icons/faDownOutlinedBlack.svg';
import MetricPlayerButtonWithPercent from '../MetricPlayerButtonWIthPercent';
import './index.css';

const TeamMetrics = ({ match, teamStats }: { match: any; teamStats: any }) => {
  const { teamId } = useParams();
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const { setExpandedGameClickedId } = teamReducer.actions;
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [t] = useTranslation();
  const toggleSelectedMetric = (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev: any) =>
          prev.filter((el: any) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev: any) => [
          ...prev.filter((p: any) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const makeLink = (matches: any[], stats?: any[], part?: any) => {
    let link = '/player?';
    let added = false;
    matches.forEach((match) => {
      if (match.id) {
        link = `${link}${added ? '&' : ''}eventId=${match.id}`;
        added = true;
      }
    });
    if (teamId) {
      if (part === 'highlights') {
        link = `${link}${added ? '&' : ''}teamId=${teamId}`;
        added = true;
      } else {
        link = `${link}${added ? '&' : ''}teamId=${teamId}`;
        added = true;
      }
    }
    added = true;
    if (stats?.length) {
      stats.forEach((stat: any) => {
        link = `${link}${added ? '&' : ''}m=${stat.key}`;
        added = true;
      });
    }
    link = `${link}&pl=${teamId}`;
    return link;
  };
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const makeLinkAndNavigate = (stat: any) => {
    navigate(makeLink([{ id: null }], [stat]));
  };
  const onMousEnter = (e: any, stat: any) => {
    e.preventDefault();
    if (stat?.percent !== null && stat?.percent !== undefined) {
      setHoveredMetricFilters((prev) => [...prev, stat.key]);
    }
  };
  return (
    <>
      <Flex
        className="team-profile-block-column-title team-profile-block-stats-column-title"
        style={{ paddingBottom: 4 }}
        justify="space-between"
        gap={56}
      >
        <div style={{ flex: 1, maxWidth: 240 }} className="stats-header-v2">
          {t('Actions')}
        </div>
        <Flex gap={48} style={{ flex: 1 }}>
          <div style={{ minWidth: 130, flex: 1 }} className="stats-header-v2">
            {match.teams[0].name}
          </div>
          <div className="stats-header-v2" style={{ minWidth: 130, flex: 1 }}>
            {match.teams[1].name}
          </div>
        </Flex>
      </Flex>
      <Flex vertical style={{ margin: '0 0px 8px 0px', alignSelf: 'stretch' }}>
        {teamStats?.metrics?.map((stat: any, index: number) => {
          return (
            <ConfigProvider>
              <Flex
                justify="flex-start"
                className={'team-profile-game-stat-row'}
                style={{ width: '100%', margin: '4px 0px', paddingLeft: 12 }}
                onMouseEnter={(e: any) => {
                  onMousEnter(e, stat);
                }}
                onMouseLeave={() => {
                  setHoveredMetricFilters((prev) =>
                    prev.filter((el: string) => el !== stat.key),
                  );
                }}
              >
                <Flex
                  justify="space-between"
                  style={{ width: '100%', paddingRight: 48, zIndex: 2 }}
                  gap={48}
                >
                  <Flex
                    style={{
                      marginLeft: 0,
                      marginRight: 8,
                      overflow: 'hidden',
                      width: '100%',
                      maxWidth: 240,
                      justifyContent: 'space-between',
                    }}
                    align="center"
                    justify="center"
                    gap={8}
                  >
                    <Flex gap={8} align="center">
                      {t(stat.label)}
                      {stat.children?.length && (
                        <DownOutlinedBlack
                          className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                          onClick={() => {
                            if (
                              stat.children?.length &&
                              expandedStats.includes(stat.key)
                            ) {
                              setExpandedStats((prev) =>
                                prev.filter(
                                  (item: string) => item !== stat.key,
                                ),
                              );
                            } else {
                              setExpandedStats((prev) => [...prev, stat.key]);
                            }
                          }}
                        />
                      )}
                    </Flex>
                    <MetricFilter
                      asPoint={false}
                      metric={stat.key}
                      selectedMetricFilters={selectedMetricFilters}
                      hoveredMetricFilter={hoveredMetricFilter}
                      toggleSelectedMetric={toggleSelectedMetric}
                    />
                  </Flex>
                  <Flex gap={48} flex={1}>
                    {stat.status === 'loading' ? (
                      <div
                        style={{
                          borderRadius: 8,
                          overflow: 'hidden',
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <Skeleton.Button
                          active
                          className="w100"
                          style={{
                            minWidth: 130,
                            minHeight: 32,
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ minWidth: 130, minHeight: 32, flex: 1 }}>
                        <MetricPlayerButtonWithPercent
                          match={match}
                          statKey={stat.key}
                          stat={stat.home_team}
                          indexStats={index}
                          teamId={match.teams[0].id || ''}
                          selectedMetricFilters={selectedMetricFilters}
                          setExpandedGameClickedId={setExpandedGameClickedId}
                          teamType={''}
                        />
                      </div>
                    )}
                    {stat.status === 'loading' ? (
                      <div
                        style={{
                          borderRadius: 8,
                          overflow: 'hidden',
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <Skeleton.Button
                          active
                          className="w100"
                          style={{
                            minWidth: 130,
                            minHeight: 32,
                            flex: 1,
                            width: '100%',
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ minWidth: 130, minHeight: 32, flex: 1 }}>
                        <MetricPlayerButtonWithPercent
                          match={match}
                          statKey={stat.key}
                          stat={stat.away_team}
                          indexStats={index}
                          teamId={match.teams[1].id || ''}
                          selectedMetricFilters={selectedMetricFilters}
                          setExpandedGameClickedId={setExpandedGameClickedId}
                          teamType={''}
                        />
                      </div>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {stat?.children?.length > 0 && (
                <Flex
                  vertical
                  style={{ width: '100%', margin: '4px 0px' }}
                  gap={8}
                  className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                >
                  {stat.children.map((child: any, childIndex: number) => {
                    return child.status === 'loading' ? (
                      <Flex
                        gap={46}
                        className="team-profile-game-stat-row child"
                        justify="space-between"
                        style={{
                          width: '100%',
                          paddingLeft: 24,
                          paddingRight: 48,
                        }}
                        align="center"
                        onMouseEnter={(e: any) => {
                          onMousEnter(e, child);
                        }}
                        onMouseLeave={() => {
                          setHoveredMetricFilters((prev) =>
                            prev.filter((el: string) => el !== child.key),
                          );
                        }}
                      >
                        <Flex
                          style={{
                            marginLeft: 0,
                            marginRight: 8,
                            overflow: 'hidden',
                            width: '100%',
                            maxWidth: 230,
                            zIndex: 2,
                            justifyContent: 'space-between',
                          }}
                          align="center"
                          justify="center"
                          gap={8}
                        >
                          <div>{t(child.label)}</div>
                          <MetricFilter
                            asPoint={false}
                            metric={child.key}
                            selectedMetricFilters={selectedMetricFilters}
                            hoveredMetricFilter={hoveredMetricFilter}
                            toggleSelectedMetric={toggleSelectedMetric}
                          />
                        </Flex>
                        <Flex gap={48} flex={1}>
                          <div
                            style={{
                              borderRadius: 8,
                              overflow: 'hidden',
                              flex: 1,
                              width: '100%',
                            }}
                          >
                            <Skeleton.Button
                              active
                              className="w100"
                              style={{
                                minWidth: 130,
                                minHeight: 32,
                                width: '100%',
                              }}
                            />
                          </div>
                          <div
                            style={{
                              borderRadius: 8,
                              overflow: 'hidden',
                              flex: 1,
                              width: '100%',
                            }}
                          >
                            <Skeleton.Button
                              active
                              className="w100"
                              style={{
                                minWidth: 130,
                                minHeight: 32,
                              }}
                            />
                          </div>
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex
                        gap={46}
                        className="team-profile-game-stat-row child"
                        justify="space-between"
                        style={{
                          width: '100%',
                          paddingLeft: 24,
                          paddingRight: 48,
                          zIndex: 2,
                        }}
                        align="center"
                        onMouseEnter={(e: any) => {
                          onMousEnter(e, child);
                        }}
                        onMouseLeave={() => {
                          setHoveredMetricFilters((prev) =>
                            prev.filter((el: string) => el !== child.key),
                          );
                        }}
                      >
                        <Flex
                          style={{
                            marginLeft: 0,
                            marginRight: 8,
                            overflow: 'hidden',
                            width: '100%',
                            maxWidth: 230,
                            justifyContent: 'space-between',
                          }}
                          align="center"
                          justify="center"
                          gap={8}
                        >
                          <div>{t(child.label)}</div>
                          <MetricFilter
                            asPoint={false}
                            metric={child.key}
                            selectedMetricFilters={selectedMetricFilters}
                            hoveredMetricFilter={hoveredMetricFilter}
                            toggleSelectedMetric={toggleSelectedMetric}
                          />
                        </Flex>
                        <Flex gap={48} flex={1}>
                          <div
                            style={{
                              minWidth: 130,
                              minHeight: 32,
                              width: '100%',
                              flex: '1 1 100%',
                              zIndex: 2,
                            }}
                          >
                            <MetricPlayerButtonWithPercent
                              match={match}
                              statKey={child.key}
                              stat={child.home_team}
                              indexStats={childIndex}
                              teamId={match.teams[0].id || ''}
                              selectedMetricFilters={selectedMetricFilters}
                              setExpandedGameClickedId={
                                setExpandedGameClickedId
                              }
                              teamType={''}
                            />
                          </div>
                          <div
                            style={{
                              minWidth: 130,
                              minHeight: 32,
                              flex: '1 1 100%',
                              zIndex: 2,
                            }}
                          >
                            <MetricPlayerButtonWithPercent
                              match={match}
                              statKey={child.key}
                              stat={child.away_team}
                              indexStats={childIndex}
                              teamId={match.teams[1].id || ''}
                              selectedMetricFilters={selectedMetricFilters}
                              setExpandedGameClickedId={
                                setExpandedGameClickedId
                              }
                              teamType={''}
                            />
                          </div>
                        </Flex>
                      </Flex>
                    );
                  })}
                  {stat.children?.length && (
                    <Divider style={{ width: '100%', height: 1, margin: 0 }} />
                  )}
                </Flex>
              )}
            </ConfigProvider>
          );
        })}
      </Flex>
    </>
  );
};
export default TeamMetrics;
