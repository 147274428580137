import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { ClubsAPI } from 'api/clubs';
import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as PlayCircleFilledWhite } from '../../../assets/img/icons/faPlayCircleFilledWhite.svg';
// import { ReactComponent as FavoritesIcon } from '../../../assets/img/icons/faStarWithPlusDark.svg';
import './index.css';
import TeamTournamentTableController from '../TeamTournamentTableController';

const TeamHeaderV2 = () => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const [openTournamentTable, setOpenTournamenTable] = useState(false);
  const { teamProfileMode, teamData } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { setTeamProfileMode, setTeamData } = teamReducer.actions;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        dispatch(setTeamData([]));
        const teamResponse = await ClubsAPI.getTeam(teamId);
        dispatch(setTeamData(teamResponse.data));
      }
    };
    getLinup().then();
  }, [teamId]);
  useEffect(() => {
    if (
      searchParams.get('teamProfileMode') === 'overview' ||
      !searchParams.get('teamProfileMode')
    ) {
      dispatch(setTeamProfileMode('overview'));
    } else if (searchParams.get('teamProfileMode') === 'players') {
      dispatch(setTeamProfileMode('players'));
    }
  }, [teamId]);

  const dispatch = useAppDispatch();

  return (
    <>
      <Flex vertical>
        <Flex>
          <div
            className=""
            style={{
              display: 'grid',
              width: '100%',
              marginTop: 8,
              gridTemplateColumns: 'repeat(9, 1fr)',
            }}
          >
            <Flex className="g-col-3" gap={4} flex={1}>
              <Flex gap={21}>
                <Flex
                  align="center"
                  justify="center"
                  style={{
                    width: 76,
                    height: 76,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <img
                    style={{
                      height: '100%',
                    }}
                    src={teamData?.image || LogoGrey}
                  />
                </Flex>
                <Flex
                  vertical
                  // style={{ marginTop: 4, marginBottom: 12 }}
                  justify="center"
                >
                  <div className="team-player-name">{teamData?.name}</div>
                  <div className="team-league-name">
                    {teamData?.league?.country?.name}. {teamData?.league?.name}
                  </div>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              className="g-col-3"
              gap={4}
              align="center"
              justify="center"
              flex={1}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      trackBg: 'var(--segmentedGroupBg)',
                    },
                  },
                  // token: {
                  //   paddingSM: 12,
                  //   paddingXXS: 12,
                  // },
                }}
              >
                <Segmented
                  style={{ width: '100%' }}
                  size="large"
                  color="black"
                  block
                  value={teamProfileMode}
                  options={[
                    {
                      label: t('Overview'),
                      value: 'overview',
                      className: 'segment-class',
                    },
                    {
                      label: t('Players'),
                      className: 'segment-class',
                      value: 'players',
                    },
                    // {
                    //   label: t('Episodes search'),
                    //   value: 'search',
                    //   className: 'segment-class',
                    // },
                  ]}
                  onChange={(value) => {
                    if (value === 'overview') {
                      setSearchParams((params: any) => {
                        params.set('teamProfileMode', 'overview');
                        return params;
                      });
                    } else {
                      setSearchParams((params: any) => {
                        params.set('teamProfileMode', 'players');
                        return params;
                      });
                    }
                    dispatch(setTeamProfileMode(value)); // string
                  }}
                />
              </ConfigProvider>
            </Flex>
            <Flex
              className="g-col-3"
              gap={4}
              flex={1}
              align="center"
              justify={'flex-end'}
            >
              {teamProfileMode === 'search' ? (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        paddingInline: 12,
                      },
                    },
                  }}
                >
                  <Button icon={<PlayCircleFilledWhite />} type="primary">
                    {t('View {} episodes')}
                  </Button>
                </ConfigProvider>
              ) : (
                <></>
                // <Button
                //   type="default"
                //   onClick={() => setOpenTournamenTable(true)}
                // >
                //   {t('Tournament table')}
                // </Button>
                // <Tooltip title={t('Add to favorites')}>
                //   <FavoritesIcon
                //     className="enabledClickable"
                //     onClick={() => handleAddToFavorite()}
                //   />
                // </Tooltip>
              )}
            </Flex>
          </div>
        </Flex>
      </Flex>
      <TeamTournamentTableController
        isOpen={openTournamentTable}
        onClose={() => setOpenTournamenTable(false)}
      />
    </>
  );
};
export default TeamHeaderV2;
