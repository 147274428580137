/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import PerPlayerMetrics from './PerPlayerMetrics';
import TeamMetrics from './TeamMetrics';

const MatchStatsContainer = ({ match }: { match: any }) => {
  const [t] = useTranslation();
  const [teamStatsMode, setTeamStatsMode] = useState('team');
  const [selectedMetricTypes, setSelectedMetricTypes] = useState<string | null>(
    null,
  );
  const GAME_STAT = {
    home_team: match.teams[0].name,
    away_team: match.teams[1].name,
    metrics: [
      {
        label: t('Shots'),
        key: 'shot',
        status: 'ready',
        percent: 0,
        home_team: {
          value: 10,
          average: 10,
        },
        away_team: {
          value: 20,
          average: 10,
        },
      },
      {
        label: t('Passes'),
        key: 'pass',
        status: 'ready',
        home_team: {
          value: 0,
          average: 10,
        },
        away_team: {
          value: 0,
          average: 10,
        },
        children: [
          {
            label: t('forward__'),
            status: 'ready',
            key: 'pass_forward',
            home_team: {
              value: 0,
              average: 10,
            },
            away_team: {
              value: 0,
              average: 10,
            },
          },
          {
            label: t('progressive'),
            status: 'ready',
            key: 'pass_progressive',
            home_team: {
              value: 0,
              average: 10,
            },
            away_team: {
              value: 0,
              average: 10,
            },
          },
          {
            label: t('key'),
            status: 'loading',
            key: 'key_pass',
          },
          {
            label: t('to 3/3'),
            status: 'loading',
            key: 'pass_to_3rd',
          },
          {
            label: t('to pen. area'),
            status: 'loading',
            key: 'pass_to_box',
          },
          {
            label: t('crosses'),
            status: 'loading',
            key: 'pass_cross',
          },
          {
            label: t('long'),
            status: 'loading',
            key: 'pass_long',
          },
          // <ConfigProvider
          //   theme={{
          //     token: {
          //       marginLG: 0,
          //     },
          //   }}
          // >
          //   <Divider />
          // </ConfigProvider>,
        ],
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [teamStats, setTeamStats] = useState(GAME_STAT);
  const { gameRowPerTeamStats } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  useEffect(() => {
    setTeamStats(gameRowPerTeamStats[match.id]);
  }, [gameRowPerTeamStats]);
  return (
    <Flex className="match-skills-container">
      {/* <Flex align="center">
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                trackBg: 'var(--segmentedGroupBg)',
                itemColor: 'red',
              },
            },
            token: {
              fontSize: 14,
              fontSizeLG: 14,
              controlHeight: 25.6,
              paddingSM: 12,
              paddingXXS: 12,
            },
          }}
        >
          <Segmented
            style={{ width: '245px' }}
            size="large"
            color="black"
            block
            value={teamStatsMode}
            options={[
              {
                label: t('Team'),
                value: 'team',
                className: 'segment-class',
              },
              {
                label: t('Players'),
                value: 'players',
                className: 'segment-class',
              },
            ]}
            onChange={(value) => {
              setTeamStatsMode(value);
            }}
          />
          <Select
            placeholder={
              <div className="select-placeholder">{t('General metrics')}</div>
            }
            variant="borderless"
            className={'hasSelection'}
            value={selectedMetricTypes}
            onSelect={(value: string) => {
              if (value === 'clear') {
                setSelectedMetricTypes(null);
              } else {
                setSelectedMetricTypes(value);
              }
            }}
            popupMatchSelectWidth={false}
            placement="bottomRight"
          ></Select>
        </ConfigProvider>
      </Flex>
      <Divider
        style={{ width: '98%', marginTop: 8, marginBottom: 7, height: 1 }}
      /> */}
      {teamStatsMode === 'team' ? (
        <TeamMetrics match={match} teamStats={teamStats} />
      ) : (
        <PerPlayerMetrics />
      )}
    </Flex>
  );
};
export default MatchStatsContainer;
