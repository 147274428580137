import './index.css';
import React, { FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GetCamerasListAction } from 'actions/equipment.actions';
import RadioSelected from 'assets/img/RadioSelected.svg';
import RadioUnselected from 'assets/img/RadioUnselected.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { CameraType } from 'types/types';

interface CameraSelectionListInterface {
  value: CameraType | null | undefined;
  callbackFunction: any;
  anchorRefData: any;
}
const CameraSelectionList: FC<CameraSelectionListInterface> = ({
  value,
  callbackFunction,
  anchorRefData,
}) => {
  const { cameras } = useSelector(
    (state: AppStateType) => state.equipmentReducer,
  );
  const [readyCameras, setReadyCameras] = useState<Array<CameraType>>([]);
  const [notReadyCameras, setNotReadyCameras] = useState<Array<CameraType>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetCamerasListAction());
  }, []);
  useEffect(() => {
    if (cameras) {
      const notReady = cameras?.filter((camera) => {
        return !camera.status?.readyToRecord;
      });
      const ready = cameras?.filter((camera) => {
        return camera?.status?.readyToRecord;
      });
      setNotReadyCameras(notReady);
      setReadyCameras(ready);
    }
  }, [cameras]);
  // const readyCameras = CAMERAS.filter((camera) => {
  //     return camera.status?.readyToRecord
  // });
  // const notReadyCameras = CAMERAS.filter((camera) => {
  //     return !camera.status?.readyToRecord
  // });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const onOptionClick = (value: any) => {
    callbackFunction(value);
  };
  const containerRef = useRef<any>(null);
  const [t] = useTranslation();
  return (
    <div
      className="dropDownRadioContainer"
      style={{
        top: `${anchorRefData.top + anchorRefData.height + 10}px`,
        left: `${anchorRefData.left}px`,
        width: `${anchorRefData.width - 20}px`,
      }}
      ref={containerRef}
    >
      <div className={'flex-row f-ga-8 ai-c'}>
        <img src={RadioSelected} />
        <div>{value ? value.name : t('Not chosen')}</div>
      </div>
      <div className={'hr'} />
      {readyCameras.length > 0 && (
        <div
          style={{
            width: `${containerRef?.current?.width}px`,
            position: 'relative',
            top: `${containerRef?.current?.width}px`,
          }}
          className="dropdownRadioListContainer flex-row j-sb"
        >
          <div className="innerDropdownListContainer flex-column j-sb">
            {readyCameras.map((camera: CameraType) => {
              return (
                <div
                  className="teamDDListElement"
                  onMouseDown={() => {
                    onOptionClick(camera);
                  }}
                >
                  <img src={RadioUnselected} />
                  <label>{camera.name}</label>
                </div>
              );
            })}
            <div className={'hr'} />
            {notReadyCameras.map((camera: CameraType) => {
              return (
                <div
                  className="teamDDListElement"
                  data-tooltip-id={'evtTooltipName'}
                  data-tooltip-content={t('Camera is not avaliable')}
                  data-tooltip-place="left"
                >
                  <label>{camera.name}</label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default CameraSelectionList;
