import React from 'react';

import { ConfigProvider, Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

const MatchFilters = ({
  show,
  selectedPositions,
  setSelectedPositions,
  playedSchemas,
  selectedPlayedSchemas,
  setSelectedPlayedSchemas,
  selectedMinutes,
  setSelectedMinutes,
}: {
  show: boolean;
  selectedPositions: any;
  setSelectedPositions: any;
  playedSchemas: any;
  setPlayedSchemas: any;
  selectedPlayedSchemas: any;
  setSelectedPlayedSchemas: any;
  selectedMinutes: any;
  setSelectedMinutes: any;
}) => {
  const [t] = useTranslation();
  const TIME_OPTIONS = [
    {
      label: t('> 1 min'),
      value: 'ANY',
    },
    {
      label: t('> 15 min'),
      value: '15',
    },
    {
      label: t('> 30 min'),
      value: '30',
    },
    {
      label: t('> 45 min'),
      value: '45',
    },
    {
      label: t('> 60 min'),
      value: '60',
    },
    {
      label: t('> 75 min'),
      value: '75',
    },
    {
      label: t('> 90 min'),
      value: '90',
    },
  ];
  const { gamesFilterPositions, positionsFilterDurations } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  return (
    show && (
      <Flex align="center" justify="flex-start">
        {gamesFilterPositions.length > 0 && (
          <ConfigProvider
            theme={{
              token: {
                colorText: 'var(--colorPrimaryBase, #1668DC)',
              },
              components: {
                Select: {
                  optionSelectedColor:
                    'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                },
              },
            }}
          >
            <Select
              placeholder={
                <div className="select-placeholder">{t('All positions')}</div>
              }
              variant="borderless"
              className={'hasSelection'}
              value={selectedPositions}
              onSelect={(value: string) => {
                if (value === 'clear') {
                  setSelectedPositions(null);
                } else {
                  setSelectedPositions(value);
                }
              }}
              popupMatchSelectWidth={false}
              placement="bottomRight"
            >
              <Select.Option
                style={{
                  mpaddingTop: 10,
                  borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                  borderRaius: 0,
                  color: 'black',
                }}
                className="enabledClickable"
                key={0}
                value={'clear'}
              >
                {t('Clear filter')}
              </Select.Option>
              {gamesFilterPositions.map((position: any, index: number) => (
                <Select.Option
                  key={index + 1}
                  label={position.value}
                  value={position.value}
                  style={{
                    color: 'black',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: 5,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className="positions-filter-name">
                      {position.label}
                    </div>
                    <div className="positions-filter-timer">
                      (
                      {Math.round(
                        positionsFilterDurations[position.value] / 1000 / 60,
                      )}
                      ’)
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </ConfigProvider>
        )}
        <div className="enabledClickable hasSelection">
          {playedSchemas.length > 0 && (
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorPrimaryBase, #1668DC);',
                },
                components: {
                  Select: {
                    optionSelectedColor:
                      'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                  },
                },
              }}
            >
              <Select
                placeholder={
                  <div className="select-placeholder">
                    {t('All formations')}
                  </div>
                }
                variant="borderless"
                value={selectedPlayedSchemas}
                popupMatchSelectWidth={false}
                onSelect={(value: string) => {
                  if (value === 'clear') {
                    setSelectedPlayedSchemas(null);
                  } else {
                    setSelectedPlayedSchemas(value);
                  }
                }}
                placement="bottomRight"
              >
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                    color: 'black',
                  }}
                  className="enabledClickable"
                  key={0}
                  onClick={(e: any) => {
                    e.preventDefault();
                    // setSelectedPlayedSchemas(undefined);
                  }}
                  label={''}
                  value={'clear'}
                >
                  {t('Clear filter')}
                </Select.Option>
                {playedSchemas.map((schema: any, index: number) => (
                  <Select.Option
                    key={index + 1}
                    label={schema.value}
                    value={schema.value}
                    style={{
                      color: 'black',
                    }}
                  >
                    {schema.label}
                  </Select.Option>
                ))}
              </Select>
            </ConfigProvider>
          )}
        </div>
        <div className="hasSelection">
          <ConfigProvider
            theme={{
              token: {
                colorText: 'var(--colorPrimaryBase, #1668DC);',
              },
              components: {
                Select: {
                  optionSelectedColor:
                    'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                },
              },
            }}
          >
            <Select
              placeholder={
                <div className="select-placeholder">{t('Played time')}</div>
              }
              listHeight={270}
              variant="borderless"
              value={selectedMinutes}
              popupMatchSelectWidth={false}
              onSelect={(value: string) => {
                if (value === 'clear') {
                  setSelectedMinutes(null);
                } else {
                  setSelectedMinutes(value);
                }
              }}
              placement="bottomRight"
              className={'hasSelection'}
            >
              <Select.Option
                style={{
                  mpaddingTop: 10,
                  borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                  borderRaius: 0,
                  color: 'black',
                }}
                className="enabledClickable"
                key={0}
                onClick={(e: any) => {
                  e.preventDefault();
                }}
                label={''}
                value={'clear'}
              >
                {t('Clear filter')}
              </Select.Option>
              {TIME_OPTIONS.map((option: any, index: number) => (
                <Select.Option
                  key={index + 1}
                  label={option.value}
                  value={option.value}
                  style={{
                    color: 'black',
                  }}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </ConfigProvider>
        </div>
      </Flex>
    )
  );
};
export default MatchFilters;
