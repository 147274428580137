import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import EpisodesSearchContainer from './EpisodesSearchContainer';
import PlayerDataBlock from './PlayerDataBlock';
import PlayerInfoBlock from './PlayerInfoBlock';
import PlayerMultitool from './PlayerMultitool';

import './index.css';

// import GameProtocolSidePanel from '../GameProtocolSidePanel';
const PlayerPageController = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { teamPlayerProfileMode } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { setTopPlayerRole } = teamPlayerReducer.actions;
  useEffect(() => {
    dispatch(setTopPlayerRole(null));
  }, []);

  const [playerProfileMode, setPlayerProfileMode] = useState('Overview');
  const [t] = useTranslation();
  const { playerId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (
      searchParams.get('playerProfileMode') === 'overview' ||
      !searchParams.get('playerProfileMode')
    ) {
      setPlayerProfileMode(t('Overview'));
    } else if (searchParams.get('playerProfileMode') === 'multitool') {
      setPlayerProfileMode(t('Multitool'));
    }
  }, [playerId]);
  return (
    <div className="player-page-controller">
      <PlayerInfoBlock
        playerProfileMode={playerProfileMode}
        setPlayerProfileMode={setPlayerProfileMode}
      />
      <div
        style={{
          display: playerProfileMode === t('Overview') ? 'block' : 'none',
        }}
      >
        <PlayerDataBlock />
      </div>
      <div
        style={{
          display: playerProfileMode !== t('Overview') ? 'block' : 'none',
        }}
      >
        <PlayerMultitool playerProfileMode={playerProfileMode} />
      </div>
      <GameProtocolSidePanel />
    </div>
  );
};
export default PlayerPageController;
