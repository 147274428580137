import React, { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ConfigProvider, Flex, Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import './index.css';
import { ClubsAPI } from 'api/clubs';
import { AppStateType } from 'reducers';
import { PLAYER_ROLES_ABBREVIATED } from 'types/constants';

const TeamPlayersTableController = () => {
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seasons, setSeasons] = useState<any[]>([]);
  const [players, setPlayers] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [averageByGame, setAverageByGame] = useState<any>('total');
  const { teamId } = useParams();
  const { selectedSeason } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  useEffect(() => {
    if (selectedSeason && teamId) {
      ClubsAPI.getLineupStats(
        teamId,
        null,
        'current',
        selectedSeason.id,
        averageByGame,
      ).then((playersResponse) => {
        const playersList = playersResponse.data;
        setPlayers(playersList);
      });
    }
  }, [selectedSeason, averageByGame]);
  interface DataType {
    key: React.Key;
  }
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: 'number',
      key: 'number',
      title: t('#'),
      fixed: 'left',
    },
    {
      dataIndex: 'full_name',
      key: 'name',
      title: t('Full name-player'),
      render: (text: string, record: any) => (
        <div
          className="enabledClickable underline"
          onClick={() => {
            window.open(`/players/${record.id}`, '_blank');
          }}
        >
          {text}
        </div>
      ),
      fixed: 'left',
    },
    {
      dataIndex: 'nationality',
      key: 'nationality',
      title: t('Nationality'),
      className: 'left-column-border',
    },
    {
      dataIndex: 'age',
      key: 'age',
      title: t('Age'),
    },
    {
      dataIndex: 'role',
      key: 'role',
      title: t('Position'),
      render: (text) => <>{PLAYER_ROLES_ABBREVIATED[text]}</>,
    },
    {
      dataIndex: 'matches_count',
      key: 'matches_count',
      title: t('Matches played'),
    },
    {
      dataIndex: 'goal',
      key: 'goals',
      title: t('Goals'),
      // render: (text: number) => (text > 0 ? text : '—'),
    },
    {
      dataIndex: 'assist',
      key: 'assist',
      title: t('Assists_passes'),
      // render: (text: number) => (text > 0 ? text : '—'),
    },
    // {
    //   dataIndex: 'shot',
    //   key: 'shot',
    //   title: t('Shots'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
    // {
    //   dataIndex: 'pass',
    //   key: 'pass',
    //   title: t('Passes-p'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
    // {
    //   dataIndex: 'key_pass',
    //   key: 'key_pass',
    //   title: t('Key_pass-KP'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
  ];
  return (
    <div className="team-page-controller">
      <div className="block-wrapper players-table">
        {/* <Flex
          justify="space-between"
          style={{
            paddingTop: 8,
            paddingBottom: 8,
          }}
        > */}
        {/* <div className="block-title">
          <div className="block-title-h">{t('Players')}</div>
        </div> */}
        {/* <div>
            <ConfigProvider
              theme={{
                token: {
                  fontSize: 14,
                  lineHeight: 22,
                },
              }}
            >
              <Segmented
                defaultValue={averageByGame}
                options={[
                  { label: t('On average per match'), value: 'average' },
                  { label: t('Total'), value: 'total' },
                ]}
                onChange={setAverageByGame}
              />
            </ConfigProvider>
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))',
                },
              }}
            >
              <Select
                // style={{minWidth: 130}}
                variant="borderless"
                value={selectedSeason}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onSelect={(value: string) => {
                  // alert(value);
                  // if (value === 'manual') {
                  //   setSelectedSeason(value);
                  //   dispatch(setShowGameSelectionModal(true));
                  // } else {
                  //   dispatch(setSelectedMatchesStats([]));
                  //   dispatch(setStatsCustomGameListPlayer([]));
                  //   setSelectedSeason(value);
                  // }
                }}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              >
                {seasons.map((season: any, index: number) => (
                  <Select.Option
                    key={index}
                    label={season.value}
                    value={season.value}
                  >
                    {season.label}
                  </Select.Option>
                ))}
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderTop: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                  }}
                  label={'last10'}
                  value={'last10'}
                >
                  {t('10 last games')}
                </Select.Option>
                <Select.Option label={'last5'} value={'last5'}>
                  {t('5 last games')}
                </Select.Option>
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderTop: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                  }}
                  label={'manual'}
                  value={'manual'}
                >
                  {t('Manual...')}
                </Select.Option>
              </Select>
            </ConfigProvider>
          </div> */}
        {/* </Flex> */}
        <Flex>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  rowHoverBg: 'rgba(0, 0, 0, 0.04)',
                },
              },
            }}
          >
            <Table
              dataSource={players}
              columns={columns}
              style={{
                width: '100%',
              }}
              pagination={false}
            />
          </ConfigProvider>
        </Flex>
      </div>
    </div>
  );
};
export default TeamPlayersTableController;
