/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Tooltip as ATooltip,
  ConfigProvider,
  // ConfigProvider,
  Divider,
  Flex,
  Popover,
  Segmented,
  Select,
  Skeleton,
  // Tree,
  // TreeDataNode,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import {
  GetTeamAllMatches,
  SetSelectedTeamLastMatch,
} from 'actions/team.actions';
import { ClubsAPI } from 'api/clubs';
import { EventsAPI } from 'api/events';
import GameSelectSidePanel from 'components/GameSelectSidePane';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';
import { getMatchWord } from 'utils/functions';

import './index.css';
import PerPlayerStatistics from './PerPlayerStatistics';
import PerTeamStatistics from './PerTeamStatistics';
import { TeamStatisticsContent } from './SettingPopover';

const TeamStatisticsBlockV2 = () => {
  const [t] = useTranslation();
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    selectedMatchesStats,
    teamData,
    selectedTournaments,
    tableMatches,
    appliedLastMatch,
    selectedSeason,
    selectedRowKeys,
  } = useSelector((state: AppStateType) => state.teamReducer);
  const { setSelectedSeason, setSelectedMatchesStats } = teamReducer.actions;
  const [teamStatsMode, setTeamStatsMode] = useState('team');
  const [teamValuesStatsMode, setTeamValuesStatsMode] = useState('sum');
  const dispatch = useAppDispatch();
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [selectedMetricTypes, setSelectedMetricTypes] = useState<string | null>(
    null,
  );
  // const [selectedSeason, setSelectedSeason] = useState<string>();
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const STATS_PLACEHOLDER = [
    {
      label: t('Goals'),
      key: 'goal',
      status: 'loading',
    },
    {
      label: t('Shots'),
      key: 'shot',
      status: 'loading',
    },
    {
      label: t('Corners'),
      key: 'corner',
      status: 'loading',
    },
    {
      label: t('Free kicks'),
      key: 'freekick',
      status: 'loading',
      children: [
        {
          label: t('direct freekicks'),
          status: 'loading',
          key: 'freekick_shot',
        },
        {
          label: t('indirect freekicks'),
          status: 'loading',
          key: 'freekick_pass',
        },
      ],
    },
    {
      label: t('Throw-ins'),
      status: 'loading',
      key: 'out',
    },
    {
      label: t('Goal kicks'),
      status: 'loading',
      key: 'goal_kick',
    },
    {
      label: t('Penalties'),
      status: 'loading',
      key: 'penalty',
    },
    {
      label: t('Passes'),
      key: 'pass',
      status: 'loading',
      children: [
        {
          label: t('assists'),
          status: 'loading',
          key: 'goal_pass',
        },
        {
          label: t('forward__'),
          status: 'loading',
          key: 'pass_forward',
        },
        {
          label: t('progressive'),
          status: 'loading',
          key: 'pass_progressive',
        },
        {
          label: t('key'),
          status: 'loading',
          key: 'key_pass',
        },
        {
          label: t('to 3/3'),
          status: 'loading',
          key: 'pass_to_3rd',
        },
        {
          label: t('to pen. area'),
          status: 'loading',
          key: 'pass_to_box',
        },
        {
          label: t('crosses'),
          status: 'loading',
          key: 'pass_cross',
        },
        {
          label: t('long'),
          status: 'loading',
          key: 'pass_long',
        },
        // <ConfigProvider
        //   theme={{
        //     token: {
        //       marginLG: 0,
        //     },
        //   }}
        // >
        //   <Divider />
        // </ConfigProvider>,
      ],
    },
    {
      label: t('Dribbles'),
      status: 'loading',
      key: 'dribble',
    },
    {
      label: t('Tackles'),
      status: 'loading',
      key: 'tackle',
    },
    {
      label: t('Tackles 1v1'),
      status: 'loading',
      key: 'tackle1-1',
    },
    {
      label: t('Interceptions'),
      status: 'loading',
      key: 'interception',
    },
    {
      label: t('2nd balls'),
      status: 'loading',
      key: 'recovery',
    },
    {
      label: t('Duels'),
      status: 'loading',
      key: 'duel',
      children: [
        {
          label: t('ground defensive'),
          status: 'loading',
          key: 'ground_duel_defence',
        },
        {
          label: t('aerial defensive'),
          status: 'loading',
          key: 'aerial_duel_defence',
        },
        {
          label: t('ground offensive'),
          status: 'loading',
          key: 'ground_duel_offence',
        },
        {
          label: t('aerial offensive'),
          status: 'loading',
          key: 'aerial_duel_offence',
        },
        // <ConfigProvider
        //   theme={{
        //     token: {
        //       marginLG: 0,
        //     },
        //   }}
        // >
        //   <Divider />
        // </ConfigProvider>,
      ],
    },
    {
      label: t('Fouls'),
      status: 'loading',
      key: 'foul',
    },
    {
      label: t('YC'),
      status: 'loading',
      key: 'yellow_cards',
    },
    {
      label: t('RC'),
      status: 'loading',
      key: 'red_cards',
    },
  ];
  const [teamStats, setTeamStats] = useState<any[]>(STATS_PLACEHOLDER);
  const getSeasonStats = async (seasonId: string) => {
    setTeamStats(STATS_PLACEHOLDER);
    const response = await EventsAPI.getTeamGamesStatsByPost(
      seasonId,
      teamId || '',
      '',
      '',
      // selectedMatchesStats,
    );
    const finalStats: any = [];
    teamStats.forEach((stat) => {
      const populatedStat: any = {
        label: stat.label,
        key: stat.key,
        stat: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
      };
      if (stat.children?.length > 0) {
        populatedStat.children = [];
        stat.children.forEach((childStat: any) => {
          const childPopulatedStat = {
            ...childStat,
            status: 'ready',
            stat: response.data.stats.filter(
              (s: any) => s.key === childStat.key,
            )[0],
          };
          populatedStat.children.push(childPopulatedStat);
        });
      }
      finalStats.push(populatedStat);
    });
    // console.log(finalStats);
    setTeamStats(finalStats);
  };
  const { teamId } = useParams();
  useEffect(() => {
    if (teamId) {
      // setStats([]);
      dispatch(GetTeamAllMatches(teamId));
      setSeasons([]);
      ClubsAPI.getTeamSeasons(teamId).then((seasonsResponse) => {
        const seasonsList = seasonsResponse.data;
        setSeasons(seasonsList);
        if (seasonsList !== undefined) {
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return {
              label: label,
              value: season.id,
              id: season.id,
            };
          });
          setSeasons(s);
          if (selectedSeason === null && s.length) {
            dispatch(setSelectedSeason(s[0]));
            dispatch(setSelectedMatchesStats([]));
          } else if (s.length === 0) {
            dispatch(setSelectedSeason('last10'));
          }
        }
      });
    }
  }, [teamId]);
  useEffect(() => {
    if (teamData) {
      if (
        selectedSeason &&
        selectedSeason !== 'manual' &&
        selectedMatchesStats.length === 0
      ) {
        const seasonId = selectedSeason.id || selectedSeason;
        getSeasonStats(seasonId).then();
      } else if (selectedMatchesStats.length > 0) {
        getSeasonStats('manual').then();
      }
    }
  }, [teamData, selectedSeason, selectedMatchesStats]);
  const makeLink = (matches: any[], team?: any, stats?: any[], part?: any) => {
    let link = '/player?';
    let added = false;
    matches.forEach((match) => {
      if (match.id) {
        link = `${link}${added ? '&' : ''}eventId=${match.id}`;
        added = true;
      }
    });
    if (team) {
      if (part === 'highlights') {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      } else {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      }
    }
    link = `${link}${added ? '&' : ''}season=${selectedSeason}`;
    added = true;
    if (stats?.length) {
      stats.forEach((stat: any) => {
        link = `${link}${added ? '&' : ''}m=${stat.key}`;
        added = true;
      });
    }
    link = `${link}&pl=${openedTeamPlayer.id}`;
    // if (matches.length) {
    //   console.log('matches');
    //   console.log(matches);
    //   dispatch(
    //     AC_setIntermediateActivitiesAction(
    //       matches.map((match) => {
    //         if (match.id !== null) {
    //           return match.id;
    //         }
    //       }) || [],
    //     ),
    //   );
    // }
    // dispatch(
    //   setStatsCustomGameList(
    //     matches.map((match) => {
    //       if (match.id !== null) {
    //         return match.id;
    //       }
    //     }),
    //   ),
    // );
    return link;
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const PopoverHandlerRef = React.createRef<any>();
  const [checkedSeasons, setCheckedSeasons] = useState<any[]>([]);

  const makeLinkAndNavigate = (stat: any) => {
    navigate(makeLink([{ id: null }], openedTeamPlayer.team, [stat]));
  };
  const hide = () => {
    setOpen((prev) => false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen((prev) => newOpen);
  };
  const constructMatchesString = () => {
    if (selectedRowKeys) {
      const matcheString = `${selectedRowKeys?.length} ${t(getMatchWord(selectedRowKeys?.length?.toString()))}`;
      if (selectedRowKeys?.length === 0) {
        return matcheString;
      }
      if (selectedTournaments.length === 1) {
        return `(${matcheString})`;
      }
      return matcheString;
    }
    return 0;
  };
  const [isLong, setIsLong] = useState<boolean>(false);
  const showStat = (stat: any) => {
    return (
      openedTeamPlayer &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length &&
          stat.for_sport.includes(openedTeamPlayer?.team.sport))) &&
      !stat.excluded_sports?.includes(openedTeamPlayer?.team.sport)
    );
  };
  const updateStats = (data: any) => {
    dispatch(setSelectedSeason(data));
  };
  return (
    <div className="block-wrapper right">
      <Popover
        placement="left"
        // title={t('Statistics')}
        content={
          <TeamStatisticsContent
            setSelectedSeason={updateStats}
            hide={hide}
            isOpen={open}
            firstSeason={
              tableMatches.length > 0 ? `${tableMatches[0].name}` : ''
            }
          />
        }
        open={open}
        className="some-class"
        trigger="contextMenu"
        onOpenChange={handleOpenChange}
      >
        <Flex className="block-title" align="center">
          <Flex gap={20} align="center" flex={1} justify="flex-start">
            <div className="block-title">
              <div className="block-title-h">{t('Statistics')}</div>
            </div>
            <div
              ref={PopoverHandlerRef}
              className="playerStatsPopoverHandler"
              style={{ color: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))' }}
              onClick={() => {
                setOpen((prev: boolean) => true);
                dispatch(SetSelectedTeamLastMatch(appliedLastMatch));
              }}
            >
              {isLong && (
                <ATooltip
                  title={
                    tableMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                  arrow={true}
                >
                  <div>
                    {
                      tableMatches.filter(
                        (s: any) => s.name === selectedSeasonLabel,
                      )[0]?.name
                    }
                  </div>
                </ATooltip>
              )}
              {!isLong && (
                <div>
                  {selectedTournaments.length === 1
                    ? selectedTournaments[0].split('-')[1]
                    : ''}
                  &nbsp;{constructMatchesString()}
                </div>
              )}
            </div>
          </Flex>
        </Flex>
      </Popover>
      <div className="block-content">
        <div className="block-column block-colum-player-stats">
          <div style={{ width: '100%' }}>
            <ConfigProvider
              theme={{
                components: {
                  Segmented: {
                    trackBg: 'var(--segmentedGroupBg)',
                    itemColor: 'red',
                  },
                },
                token: {
                  fontSize: 14,
                  fontSizeLG: 14,
                  controlHeight: 25.6,
                },
              }}
            >
              <Flex justify="space-between" align="center">
                <Flex>
                  <Segmented
                    style={{ width: 'fit-content' }}
                    size="large"
                    color="black"
                    block
                    className="team-stats-mode-selector"
                    value={teamValuesStatsMode}
                    options={[
                      {
                        label: t('Total'),
                        value: 'sum',
                        className: 'segment-class',
                      },
                      {
                        label: t('Per match'),
                        value: 'per_match',
                        className: 'segment-class',
                      },
                    ]}
                    onChange={(value) => {
                      setTeamValuesStatsMode(value);
                    }}
                  />
                </Flex>
              </Flex>
            </ConfigProvider>
          </div>
          <Divider style={{ margin: 'auto', minWidth: '98%', width: '98%' }} />
          {teamStatsMode === 'team' ? (
            <>
              <PerTeamStatistics
                teamStats={teamStats}
                teamValuesStatsMode={teamValuesStatsMode}
              />
            </>
          ) : (
            <PerPlayerStatistics teamValuesStatsMode={teamValuesStatsMode} />
          )}
        </div>
      </div>
      <GameSelectSidePanel />
    </div>
  );
};
export default TeamStatisticsBlockV2;
